.chart {
  &__background {
    padding-top: 32px;
    @include breakpoint(m) {
      padding-top: 52px;
      background-image: linear-gradient(0deg, rgba(219, 243, 253, 0.88), #fff);
    }
  }
  .intro__percentage-wrap {
    // padding-left: $spacing-32;
    @include breakpoint(xl) {
      // padding-left: $spacing-64;
    }
  }

  .percentage-box {
    @include fadeContent($anim-timing-250);
    background-color: $white;
    background-image: url("../../../assets/images/backgrounds/chart-background.png");
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: $spacing-16;
    box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.13);
    opacity: 1 !important;
    display: flex;
    flex-flow: column;
    padding: $spacing-24 $spacing-12;
    margin: $spacing-24 $spacing-12;
    justify-content: flex-start;
    @include breakpoint(l) {
      // background-size: contain;
      margin: 0 $spacing-12;
    }
    @include breakpoint(sm-only) {
      // background-size: contain;
    }
  }

  &__foliage {
    // @include fadeContent($anim-timing-250);
    // opacity: 1 !important;
    // margin: auto;
    // margin-top: 0;
    position: absolute;
    top: -17%;
    right: -17%;
    height: 50%;
    z-index: 10;
  }

  &__percentageSection {
    @include fadeContent($anim-timing-250);
    // border-radius: $spacing-16;
    opacity: 1 !important;
    margin: 0 auto;
    // margin-bottom: $spacing-12;
    position: relative;
  }

  &__percentageText {
    color: $white;
    font-family: "AvantGarde-Demi", sans-serif;
    text-align: center;
    font-size: 32px;
    margin: 0;
    font-weight: 100;
    padding: 0 4px;
    z-index: 50;
    position: relative;
    border-radius: $spacing-16;
    background-color: $brand-primary;

    &.second {
      background-color: $chart-secondary;
    }

    &.third {
      background-color: $brand-dark;
    }
  }

  &__plusSymbol {
    text-align: right;
    vertical-align: super;
    @include breakpoint(sm-only) {
      margin-top: 10px;
    }
  }

  &__percentageTitle {
    // @include type-heading-3;
    font-size: 80px;
    @include breakpoint(m) {
      font-size: 80px;
    }
  }

  &__percentageSymbol {
    text-align: left;
    vertical-align: top;
    line-height: 200%;
    @include breakpoint(sm-only) {
      margin-top: 10px;
    }
  }

  &__subtitle {
    @include type-heading-4;
    color: $brand-dark;
    text-align: left;
    font-size: 16px;
    margin: $spacing-32 0 $spacing-32 0;
  }

  &__moreLink {
    margin: $spacing-24 0 $spacing-32 0;
  }

  // .bird-wrap {
  //   position: absolute;
  //   right: 0%;
  //   top: -12%;

  //   @include breakpoint(sm-only) {
  //     top: -5%;
  //   }
  // }

  // .bushes-wrap {
  //   position: absolute;
  //   left: -3%;
  //   bottom: -5%;
  //   @include breakpoint(sm-only) {
  //     left: -8%;
  //     bottom: -3%;
  //   }
  // }

  // &-separator {
  //   position: absolute;
  //   top: 15%;
  //   right: 10%;
  //   display: none;
  //   @include breakpoint(l) {
  //     display: block;
  //   }
  // }
}
