.industries {
  width: 100%;
  height: 100%;
  &__app_container {
    @include questionHeaderAndTitle;
  }

  &__background {
    .background__clouds {
      width: 220vw;
    }
  }

  &__background-container {
    .background__astro {
      width: px-to-vw(240);
      bottom: px-to-vw(20);
      left: px-to-vw(1100);
    }
  }
}
