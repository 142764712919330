.back-btn {
  @include arrowButton;
  transform: rotate(180deg);
  opacity: 1;
  transition: opacity $anim-timing-250 $anim-ease-utility;

  &--visibility-hidden {
    opacity: $inactiveOpacity;
    pointer-events: none;
  }
  &:focus {
    outline: 2px solid $white !important;
  }
}
