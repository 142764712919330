@import "fonts";
@import "variables";
@import "mixins";
@import "grid";
@import "animations";
@import "oneTrust";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "SalesforceSansRegular", Helvetica, Arial, sans-serif;
  font-size: 15px;
}

.salesforce-sans-regular {
  font-family: "SalesforceSans-Regular", Helvetica, Arial, sans-serif !important;
}

@include a11y-focus();
