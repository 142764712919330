.recs-conditionals {
  &__wrap {
    @include fadeContent;
    padding-bottom: $spacing-96;
    @include breakpoint(t) {
      padding-bottom: $spacing-144;
    }
  }
  &__title {
    @include type-heading-2;
    color: $brand-dark;
    text-align: center;
  }
  &__image {
    border-radius: 16px;
  }
  &__item {
    .text__title {
      @include type-heading-3;
    }
    &--0 {
      .text__title {
        @include breakpoint(l) {
          @include type-heading-2;
        }
      }
    }
    &--1,
    &--2 {
      margin-top: $spacing-64;
      @include breakpoint(m) {
        margin-top: 0;
      }
    }
    &--3 {
      background-image: url("../../../assets/images/recs-card-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right bottom;
      margin-top: $spacing-64;
      border-radius: 16px;
      background-color: $brand-dark;
      min-height: 550px;
      @include breakpoint(l) {
        margin-top: 0;
      }
    }
    &--threeup {
      .text__title {
        @include type-heading-3;
      }
    }
  }
  &__cardlayout {
    padding-left: $spacing-64;
    padding-right: $spacing-64;
  }
  &__brandcard {
    color: white;
    padding-left: $spacing-32;
    .text__color,
    .text__title {
      color: white;
      font-size: $fs-40;
      padding-right: $spacing-64;
      letter-spacing: $ls-cta;
      text-align: left;
      line-height: $lh-116;
      min-height: 26vw;
    }
    .text__cta-container {
      position: absolute;
      bottom: $spacing-32;
    }
  }
  &__lockup {
    margin-top: $spacing-32;
    &.-left {
      text-align: left;
      margin-left: 0 !important;
    }
  }
}
.text__cta:hover {
  border: 2px solid $white;
}
