.timeline {
  &__item {
    display: flex;
    cursor: pointer;
    position: relative;
    @include breakpoint(sm-only) {
      flex-shrink: 0;
      width: $tlItemWidth;
      flex-basis: $tlItemWidth;
      &:focus {
        outline-offset: -$focusRectWidth;
      }
    }
    @include breakpoint(m) {
      margin-top: $spacing-24;
      &:first-child {
        margin-top: 0;
      }
    }

    &.-active {
      .timeline__item {
        &-title {
          opacity: 1;
        }
        &-content {
          opacity: 1;
          @include breakpoint(m) {
            visibility: visible;
            max-height: $tlItemMaxHeight;
          }
        }
        &-progress {
          animation: scaleY $tlDuration $anim-ease-linear forwards;
        }
      }
      &.-pause {
        .timeline__item-progress {
          animation-play-state: paused;
        }
      }
    }

    &-progress {
      @include timelineBar($brand-dark);
      transform-origin: top center;
      transform: scaleY(0);
      will-change: transform; //scale change here is what animates to show progress

      &-container {
        position: relative;
        width: 6px;
        border-radius: 3px;
        flex-shrink: 0;
        overflow: hidden;
        @include fixOverflow;
        @include breakpoint(sm-only) {
          display: none;
        }
      }
      &-bg {
        @include timelineBar();
        opacity: 0.25;
      }
    }
    &-title {
      @include type-heading-4;
      color: $brand-dark;
      transition: opacity $anim-timing-400 $anim-ease-linear;
      margin-top: 0;
      margin-bottom: $spacing-16;
      @include breakpoint(m) {
        margin: $spacing-8 0;
      }
    }
    &-content-container {
      display: flex;
      padding: 0;
      align-items: flex-start;
      @include breakpoint(m) {
        align-items: center;
        padding: $spacing-16;
      }
    }
    &-content {
      @include type-paragraph-2;
      color: $brand-dark;
      margin: 0;
      opacity: 0;
      transition: max-height $anim-timing-400 $anim-ease-utility,
        opacity $anim-timing-250 $anim-ease-linear;
      @include breakpoint(m) {
        max-height: 0;
      }
    }
  }
}
