.testimonial {
  background-image: url("../../../assets/images/testimonial_bg_mobile.jpg");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-top: $spacing-32;
  position: relative;
  text-align: center;
  opacity: 0;
  @include breakpoint(m) {
    background-image: url("../../../assets/images/testimonial_bg_new.jpg");
  }

  &.-active {
    animation: fadeIn $anim-timing-400 $anim-ease-utility forwards;
  }

  &__box {
    @include boxShadow;
    @include fadeContent($anim-timing-250);
    padding: $spacing-32 0;
    background-color: $white;
    border-radius: $spacing-16;
    position: relative;
    margin-top: 50px;

    @include breakpoint(m) {
      margin-left: 90px;
      margin-bottom: 150px;
    }

    @include breakpoint(sm-only) {
      margin-bottom: 60px;
    }
  }

  &__leaf--desktop {
    width: 100px;
    position: absolute;
    right: -30px;
    top: -70px;
    @include breakpoint(l) {
      right: -20px;
      top: -50px;
    }
  }

  &__leaf--mobile {
    width: 120px;
    position: absolute;
    top: -20px;
    left: -60px;
    z-index: -1;
  }

  &__title--mobile {
    @include breakpoint(sm-only) {
      margin: 0 0 50px 0;
    }
  }

  &__quotation {
    @include breakpoint(sm-only) {
      flex-basis: 0;
      padding: $spacing-20;
      padding-top: 100px;
    }

    @include breakpoint(m) {
      margin-left: 170px;
    }
  }

  &__quotes {
    display: block;
    margin-bottom: $spacing-24;
    width: 40px;
    margin-left: 0;
  }
  &__quote {
    text-align: left;
    @include type-heading-2;
    color: $brand-dark;
    margin: $spacing-24 auto $spacing-32 auto;
    position: relative;
  }

  &__image {
    @include breakpoint(sm-only) {
      width: 200px;
      position: relative;
    }
  }

  &__image-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: $z-1;
  }

  &__image-wrapper {
    position: absolute;
    @include breakpoint(sm-only) {
      margin-top: 24px;
      top: -140px;
    }
    @include breakpoint(m) {
      height: 230px;
      width: 230px;
      left: -120px;
      top: 0px;
    }
    @include breakpoint(l) {
      height: 230px;
      width: 230px;
      margin-right: 92px;
    }
  }

  &__logo-container {
    width: get-vw(5);
    height: get-vw(5);
    border-radius: 100%;
    background-color: $white;
    position: absolute;
    right: -$spacing-48;
    bottom: 0;
    z-index: $z-1;
    @include boxShadow;
    @include breakpoint(m) {
      width: 113px;
      height: 113px;
    }

    @include breakpoint(sm-only) {
      width: 55%;
      height: 55%;
      right: -$spacing-72;
    }

    &__main {
      width: 200px;
      height: 200px;
      position: initial;
      @include breakpoint(m) {
        width: 240px;
        height: 240px;
      }
    }
  }
  &__logo-image {
    max-width: 60%;
    margin: 0 auto;
  }
  &__mobile {
    @include breakpoint(sm-only) {
      flex-direction: column;
    }
  }

  &__attribution-name {
    @include type-heading-4;
    color: $brand-dark;
    margin: 0;
    text-align: left;
    @include breakpoint(sm-only) {
      font-size: 20px;
    }
  }

  &__attribution-title {
    @include type-heading-std;
    font-size: $fs-12;
    letter-spacing: $ls-pt6;
    color: $brand-accessible;
    display: block;
    margin-top: $spacing-8;
    margin-bottom: 0;
    @include breakpoint(sm-only) {
      font-size: 14px;
    }
  }

  &__attribution-history {
    @include type-heading-std;
    font-size: $fs-12;
    letter-spacing: $ls-pt6;
    color: $text-secondary;
    display: block;
    margin-top: $spacing-20;
    margin-bottom: 0;
    text-align: left;
    text-decoration: none;
    @include breakpoint(sm-only) {
      font-size: 15px;
    }
    &__icon {
      font-size: 16px;
      @include breakpoint(sm-only) {
        font-size: 20px;
      }
    }
  }

  &__fly {
    width: 24px;
    position: absolute;
    right: 15px;
    top: -120px;

    @include breakpoint(l) {
      top: -110px;
    }
  }
}
