.contact {
  @include fadeContent;
  padding: $spacing-32;
  margin-bottom: $spacing-40;
  background-image: url("../../../assets/images/contact-bg-people.png");
  background-color: white;
  border-radius: $brandBorderRadius;
  background-position: 100% 101%;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 0;
  @include breakpoint(m) {
    margin-right: $spacing-40;
  }

  &__bg {
    width: 100%;
    padding-bottom: 55%;

    @include breakpoint(m) {
      padding-bottom: 0;
    }
  }

  @include boxShadow("light");
  @include breakpoint(m) {
    background-size: 100%;
    background-position: 100% 100%;
    padding: $spacing-32 $spacing-144 $spacing-32 $spacing-32;
    min-height: 375px;
    margin-bottom: 0;
  }
  .text {
    text-align: left;
  }
  .text__body {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    @include breakpoint(m) {
      width: get-vw(6);
      max-width: 450px; //prevents bg overlap at large screens
    }
  }
  .text__cta-container {
    position: absolute;
    bottom: $spacing-32;
  }
}
