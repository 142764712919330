.recs_interstitial {
  &__wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: $z-1;
    background-color: $white;
    &.-active {
      animation: fadeOut $anim-timing-1000 3000ms $anim-ease-utility forwards;
      .recs_interstitial {
        &__clouds {
          animation: cloudDrift $anim-timing-25s $anim-ease-linear infinite;
        }
        &__dots {
          animation: spin 2500ms $anim-ease-linear infinite;
        }
        &__loader {
          animation: scaleDown $anim-timing-1000 2500ms $anim-ease-in-back
            forwards;
        }
      }
    }
  }
  &__astro-wrap {
    position: fixed;
    left: 0;
    bottom: 0;
    width: px-to-vw(160, $dw-mobile);
    @include breakpoint(m) {
      bottom: 0;
      top: 50%;
      transform: translateY(-50%);
      width: px-to-vw(240);
    }
  }
  &__astro-img {
    width: 100%;
  }
  &__loader {
    flex-direction: column;
  }
  &__ring {
    width: px-to-vw(200, $dw-mobile);
    height: px-to-vw(200, $dw-mobile);
    position: relative;
    @include breakpoint(m) {
      width: px-to-vw(270);
      height: px-to-vw(270);
    }
  }
  &__dots {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__sky-wrap {
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background: linear-gradient(180deg, #8dd1f3 -103.8%, #ffffff 96.81%);
    overflow: hidden;
    @include fixOverflow;
    position: relative;
  }
  &__loading-text {
    @include type-heading-4;
    margin-top: $spacing-32;
    text-align: center;
    color: $brand-dark;
  }
  &__clouds {
    width: 300%;
    position: relative;
    top: 35%;
    right: 0;
  }
}
