.timeline {
  &.-complete {
    .timeline {
      &__cta {
        animation: buttonAttract $anim-timing-600 $anim-ease-utility forwards;
        transform-origin: left;
      }
    }
  }
  &__app_container {
    &.app__container {
      .header {
        height: $header-height-scrolled;
        @include breakpoint(m) {
          height: $header-height;
        }
        &__logo {
          height: 100%;
          width: auto;
          &-btn {
            height: 100%;
          }
        }
      }

      main {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-32 0 $spacing-64;
        min-height: calc(
          100vh - #{$footerMobileHeight} - #{$headerMobileHeight}
        );

        @include breakpoint(m) {
          min-height: calc(
            100vh - #{$footerTabletHeight} - #{$headerMobileHeight}
          );
        }

        @include breakpoint(l) {
          min-height: calc(
            100vh - #{$footerDesktopHeight} - #{$headerMobileHeight}
          );
        }

        @include breakpoint(gn) {
          min-height: calc(
            100vh - #{$footerDesktopHeight} - #{$headerDesktopHeight}
          );
        }
      }
    }
  }
  &__row {
    @include breakpoint(sm-only) {
      margin-top: $spacing-20;
      flex-direction: column-reverse;
    }
  }

  &__title {
    @include type-heading-3;
    color: $brand-dark;
    text-align: center;

    @include breakpoint(m) {
      text-align: left;
    }
    @include breakpoint(sm-only) {
      margin: 0;
    }
  }
  &__items-col {
    overflow: hidden;
    flex-basis: auto !important;
    @include breakpoint(m) {
      overflow-x: auto;
    }
  }
  &__items {
    margin-top: $spacing-40;
    transition: transform $anim-timing-600 $anim-ease-utility;
    @include breakpoint(sm-only) {
      margin-top: 0;
      display: flex;
      flex-wrap: nowrap;
    }
    @include breakpoint(m) {
      padding: $focusRectWidth; //prevents clipping of focus rect on timeline items
    }
  }
  &__progress-and-cta-row {
    @include breakpoint(sm-only) {
      margin-top: $spacing-24;
      align-items: center;
    }
  }
  &__cta-container {
    text-align: center;
    @include breakpoint(m) {
      text-align: left;
      margin-top: $spacing-40;
      margin-bottom: $spacing-40;
    }
  }
  &__cta-inner {
    @include breakpoint(m) {
      padding: $focusRectWidth;
    }
  }
  &__cta {
    @include button-reset;
    @include button-cta;
    display: inline-block;
  }
  .wheel {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    position: absolute; //fixes a firefox wrapping issue
    @include breakpoint(sm-only) {
      width: 100%;
    }

    &__center {
      @include breakpoint(sm-only) {
        width: 55vw;
        height: 55vw;
      }

      .image {
        margin-top: 0;
      }
    }

    &__container {
      width: 100%;
      height: auto;
      @include breakpoint(m) {
        transform: scale(0.66);
      }
      @include breakpoint(l) {
        transform: scale(1);
      }
      @include breakpoint(xl) {
        transform-origin: top center;
      }
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }

  .wheel-timeline {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    position: absolute; //fixes a firefox wrapping issue
    @include breakpoint(sm-only) {
      width: 100%;
    }

    &__center {
      @include breakpoint(sm-only) {
        width: 55vw;
        height: 55vw;
      }

      .image {
        margin-top: 0;
      }
    }

    &__container {
      width: 100%;
      height: auto;
      @include breakpoint(m) {
        transform: scale(0.66);
      }
      @include breakpoint(l) {
        transform: scale(1);
      }
      @include breakpoint(xl) {
        transform-origin: top center;
      }
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }
}
