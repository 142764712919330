$altItems: 4;
.alt-priorities {
  &__row {
    @include fadeContent;
    z-index: $z-1;
  }
  &__clouds {
    opacity: 0.35;
    position: absolute;
    transform: translateY($spacing-64);
  }
  &__lockup {
    @include fadeContent;
    .text__eyebrow {
      margin-top: 0;
    }
  }
  &__cards {
    flex-wrap: nowrap;
  }
  &__content {
    padding-left: $spacing-16;
    @include breakpoint(m) {
      padding-left: $spacing-32;
    }
  }
  &__item {
    color: $white;
    background-color: $brand-accessible;
    border-radius: $pillBorderRadius;
    padding: $spacing-32 get-vw(2);
    flex-direction: column;
    margin-top: $spacing-16;
    &:first-child {
      margin-top: 0;
    }
    @include breakpoint(m) {
      &:first-child {
        margin-top: #{$spacing-8/2};
      }
      margin: #{$spacing-8/2};
      padding: $spacing-32 $spacing-24 $spacing-24;
    }

    @include breakpoint(l) {
      &:first-child {
        margin-top: $spacing-8;
      }
      padding: $spacing-40 $spacing-32;
    }
  }

  &__bg {
    position: absolute;
    bottom: 1px; //solve for hairline gap that appears at some resolutions due to pixel rounding
    left: 0;
    z-index: $z-1;
    width: 100%;
    pointer-events: none;
  }
  &__list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: $spacing-24 auto $spacing-48;

    @include breakpoint(m) {
      margin: $spacing-40 $spacing-12;
    }
    @include breakpoint(l) {
      padding: 0 $spacing-40;
      margin: $spacing-24 auto $spacing-48;
    }
    @include breakpoint(xs) {
      margin: $spacing-24 $spacing-24 $spacing-48;
    }
  }
  &__title {
    @include type-heading-2;
    margin: 0;
  }
  &__desc {
    @include type-paragraph-2;
    margin-top: $spacing-16;
    margin-bottom: $spacing-16;
    padding-right: $spacing-16;
  }
  &__button {
    @include fadeContent;
    @include button-cta(reverse);
    display: flex;
    align-items: center;
    border: 1px solid $white;
    color: $brand-accessible;
    &:hover {
      border-color: $brand-dark;
      path {
        fill: $white;
      }
      .alt-priorities__reload {
        transform: rotate(360deg);
      }
    }
    path {
      transition: fill $anim-timing-400 $anim-ease-utility;
    }
  }
  &__reload {
    margin-left: $spacing-8;
    transition: transform $anim-timing-400 $anim-ease-utility;
  }
  &__arrow-span {
    @include visuallyHidden;
  }
  &__arrow-btn {
    @include arrowButtonInline;
    margin-top: $spacing-16;
    position: relative;
    @include breakpoint(m) {
      margin-top: $spacing-24;
    }
    path {
      stroke: $brand-dark;
    }
    &:hover {
      path {
        stroke: $white;
      }
    }
  }
  &__wrap {
    background-color: $sky-gradient-bottom-match;
    padding-bottom: $spacing-144;
    position: relative;
    overflow: hidden;
  }
  &__rounded-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 101vw;
    z-index: $z-10;
  }
  &__rounded-bottom-img {
    width: 101%;
    transform: translateY(1px) translateX(-50%);
    left: 50%;
    position: relative; //solve for hairline gap that appears at some resolutions due to pixel rounding
  }
}
