/********************
**** 24-column Flexbox Grid
**** Demo @ https://codepen.io/team/instrument/pen/eYYrPRe?editors=1100
*********************/

$grid_length: 24;

@function get-vw($col, $scale: $grid-length) {
  @return #{(($col / $grid-length * 100) * ($scale / $grid-length))}vw;
}

@function get-vw-offset($col, $scale: $grid-length) {
  @return percentage(
    $number: (
      ($col / $grid-length) * ($scale / $grid-length),
    )
  );
}

@mixin cols($col, $scale: $grid-length) {
  flex: 0 0 auto;
  flex-basis: get-vw($col, $scale);
  width: get-vw($col, $scale);
  max-width: #{($max-width / $grid_length) * $col}px;
}

@mixin row() {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

@mixin col-loop($prefix) {
  @for $col from 1 through $grid-length {
    .sf-col-#{$prefix}-#{$col} {
      @include cols($col);
    }

    @if $col==1 {
      .sf-col-#{$prefix}-offset-0 {
        margin-left: 0;
      }

      .sf-col-#{$prefix}-offset-right-0 {
        margin-right: 0;
      }
    }

    .sf-col-#{$prefix}-offset-#{$col} {
      margin-left: get-vw($col);
    }

    .sf-col-#{$prefix}-offset-right-#{$col} {
      margin-right: get-vw($col);
    }
  }

  .sf-row {
    &-#{$prefix}--start {
      justify-content: flex-start;
    }

    &-#{$prefix}--center {
      justify-content: center;
    }

    &-#{$prefix}--end {
      justify-content: flex-end;
    }

    &-#{$prefix}--around {
      justify-content: space-around;
    }

    &-#{$prefix}--between {
      justify-content: space-between;
    }

    &-#{$prefix}--top {
      align-items: flex-start;
    }

    &-#{$prefix}--middle {
      align-items: center;
    }

    &-#{$prefix}--bottom {
      align-items: flex-end;
    }

    &-#{$prefix}--reverse {
      flex-direction: row-reverse;
    }

    &-#{$prefix}--nowrap {
      flex-wrap: nowrap;
    }
  }

  .sf-col-#{$prefix} {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;

    &--first {
      order: -1;
    }

    &--last {
      order: 1;
    }

    &--hide {
      display: none;
    }

    &--show {
      display: flex;
    }

    &--show-block {
      display: block;
    }

    &--show-inline-block {
      display: inline-block;
    }
  }
}

.sf-container {
  max-width: #{$max-width}px;
  width: 100%;
  margin: 0 auto;
}

.sf-row {
  @include row();
}

.sf-col {
  &.reverse {
    flex-direction: column-reverse;
  }
}

@include col-loop("xs");

@include breakpoint(between-s-and-m) {
  @include col-loop("ms");
}

@include breakpoint(m) {
  @include col-loop("md");
}

@include breakpoint(l) {
  @include col-loop("lg");
}
