header {
  position: relative;
  z-index: $z-10;
  font-family: $ff-sf;

  .nav-item {
    .menu-section > ul > li > span {
      font-family: $ff-sf--bold;
    }
  }

  .globalNavigationBarConsolidated
    .horizontalNavComponent
    #globalnavbar-header-container
    .header-right-content
    .inner
    .global-login
    a,
  .globalNavigationBarConsolidated .panel-title-login {
    font-family: $ff-sf !important;
  }
}
