.intro {
  width: 100%;
  position: relative;
  z-index: $z-0;
  overflow: hidden;

  &__wheel {
    $flyTranslate: 50px;
    @include breakpoint(m) {
      // margin-top: $spacing-64;
      // padding-top: $flyTranslate;
      min-height: 500px;
    }
    &-container {
      top: 50px;
      margin-bottom: $spacing-64;

      // @include breakpoint(l){
      //   top: -10vh;
      // }

      // @include breakpoint(header-l){
      //   top: -25vh;
      // }

      // @include breakpoint(xl){
      //   top: -22vh;
      // }
    }
    &-fly {
      display: none;
      @include breakpoint(m) {
        display: block;
        width: 44px;
        height: 44px;
        position: absolute;
        transform: translate(50px, -$flyTranslate);
      }
      @include breakpoint(l) {
        transform: translate(100px, -$flyTranslate);
      }
    }
    &-circle {
      position: relative;

      @include breakpoint(l) {
        top: 8vh;
      }

      @include breakpoint(header-l) {
        top: -10vh;
      }

      @include breakpoint(xl) {
        top: -10vh;
      }

      .gradient--left {
        width: px-to-vw(593, $dw-mobile);
        height: px-to-vw(593, $dw-mobile);
        left: px-to-vw(-270, $dw-mobile);
        top: -$spacing-48;
        @include breakpoint(s) {
          top: 0;
        }
        @include breakpoint(m) {
          width: 100vw;
          height: 100vw;
          left: -50vw;
          top: -$spacing-48;
        }
        @include breakpoint(l) {
          width: px-to-vw(1186);
          height: px-to-vw(1186);
          left: px-to-vw(-480);
          top: 0;
        }
      }
    }

    &-bluebackground {
      width: 100%;
      height: auto;
    }
    &-bluebackground-wrap {
      display: none;
      position: absolute;
      left: 0;
      top: 85%;
      width: 100%;
      display: block;
      z-index: -1;
      @include breakpoint(l) {
        top: 75%;
      }
      @include breakpoint(xs) {
        top: 90%;
      }
    }
    // &-separator {
    //   position: absolute;
    //   top: 15%;
    //   right: 10%;
    //   @include breakpoint(sm-only) {
    //     display: none;
    //   }
    // }

    &-informationText {
      margin-bottom: $spacing-24;
      margin-top: $spacing-24;
    }
    &-clouds {
      path {
        fill: rgba($sky-clouds, 0.4);
      }
    }
    &-ctas {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 0;
      padding-bottom: $spacing-32;
      @include breakpoint(m) {
        flex-direction: row;
      }
    }
    &-ctasfooter {
      padding-top: $spacing-32;
      padding-bottom: $spacing-32;
      @include breakpoint(m) {
        flex-direction: row;
        margin-top: 0;
      }
      @include breakpoint(sm-only) {
        padding-top: 0;
      }
    }
    &-start {
      @include button-cta;
      margin-right: $spacing-24;
      margin-top: $spacing-24;
      text-transform: none;
      padding: 13px 24px;
      font-size: 16px;
      font-weight: 700;
      @include breakpoint(sm-only) {
        width: 55%;
        padding: 13px 0;
      }
      @include breakpoint(xs) {
        width: 65%;
        padding: 13px 0;
        &.formula1 {
          width: 100%;
          padding: 12px 10px;
          text-align: left;
        }
      }
    }
    &-360 {
      @include button-cta($type: "clear");
      margin-top: $spacing-24;
      text-transform: none;
      padding: 13px 24px;
      font-size: 16px;
      font-weight: 700;
      @include breakpoint(sm-only) {
        width: 60%;
        padding: 13px 0;
      }
      @include breakpoint(xs) {
        width: 70%;
        padding: 13px 0;
      }
    }
  }

  .intro__top-gradient {
    &.gradient {
      width: calc(#{get-vw(22)} + 100px);
      height: calc(#{get-vw(22)} + 100px);
      top: 8%;
      opacity: 0.6;
      @include breakpoint(m) {
        right: -250px;
        top: auto;
        bottom: 75%;
        opacity: 0.8;
        width: get-vw(18);
        height: get-vw(18);
      }
    }
  }

  &__hero-img-wrap {
    // position: relative;

    @include breakpoint(sm-only) {
      margin: 2rem 0;
    }

    @include breakpoint(m) {
      align-self: center;
    }

    @include breakpoint(t) {
      align-self: center;
      &.formula1 {
        align-self: start;
        margin-top: 24px;
      }
    }
    @include breakpoint(l) {
      align-self: flex-start;
    }
    @include breakpoint(xl) {
      &.formula1 {
        max-width: 550px;
      }
    }

    // &.event {
    //   top: 5%;
    //   left: -5%;
    //   @include breakpoint(m) {
    //     left: 0;
    //   }
    //   @include breakpoint(xl) {
    //     position: relative;
    //     transform: translateY(0);
    //   }
    // }

    @include breakpoint(sm-only) {
      padding: 10px;
      // position: relative;
      // margin-top: 30vh;
      padding: 1rem;
    }

    @include breakpoint(l) {
      // transform: translateX($spacing-64); // bump over 1 col for large screens
    }

    @include breakpoint(xl) {
      // when screens are XL, prevent content shifting
      // position: rela;
      // top: 50%;
      // transform: translateY(-50%);
      // right: -($spacing-120);
    }
  }

  &__hero-img {
    $anim-separation: $anim-timing-400;
    &--people {
      @include breakpoint(sm-only) {
        padding-bottom: 1.5rem;
      }
    }
  }

  &__hero-content {
    text-align: center;
  }
  &__heading {
    @include type-heading-1;
    color: $brand-dark;
    margin-bottom: $spacing-24;
  }
  &__subheading {
    @include type-paragraph-2;
    color: $grey;
    margin-top: 0;
    margin-bottom: $spacing-24;
  }
  &__button {
    &-cta {
      @include button-cta;
      text-transform: none;
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin: 0;
      @include breakpoint(sm-only) {
        margin-left: auto;
        margin-right: auto;
        width: 55%;
        padding: 13px 0;
        &.formula1 {
          width: 100%;
        }
      }
      @include breakpoint(xs) {
        width: 65%;
        padding: 13px 0;
      }
      &.mobile {
        @include breakpoint(sm-only) {
          margin-left: initial;
        }
      }
    }
  }
  &__top-content {
    // min-height: 100vh;

    .intro-hero {
      min-height: 600px;
      // height: auto;
      padding-top: 1rem;

      @include breakpoint(m) {
        min-height: 400px;
        padding-top: 2rem;
      }

      @include breakpoint(t) {
        min-height: 400px;
        padding-top: 2rem;
      }

      @include breakpoint(l) {
        min-height: 550px;
        padding-top: 3rem;
        &.formula1 {
          min-height: 780px;
        }
      }

      // @include breakpoint(header-l) {
      @include breakpoint(xl) {
        min-height: 900px;
        // min-height: 0px;
        // height: calc(100vh - #{$headerDesktopHeight});
        // padding-top: 35px;
      }

      .image.text__eyebrow-image {
        height: 26px;
      }
    }

    .intro-hero_event {
      height: auto;
      padding-top: $spacing-24;
      min-height: calc(100vh - #{$headerDesktopHeight});

      @include breakpoint(m) {
        padding-top: $spacing-48;
      }

      @include breakpoint(header-l) {
        min-height: 768px;
        height: calc(100vh - #{$headerDesktopHeight});
        padding-top: 0;
      }

      .image.text__eyebrow-image {
        height: 26px;
      }

      .c360__title {
        position: absolute;
        top: 12%;
      }

      .c360__image {
        width: 50%;
        margin: auto;
      }
    }
  }

  &__hero {
    background-image: url("../../../assets/images/backgrounds/intro-background-formula1.png");
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    &.formula1 {
      background-image: url("../../../assets/images/backgrounds/intro-background-formula1.png");
    }
    @include breakpoint(sm-only) {
      background-image: url("../../../assets/images/backgrounds/intro-background-formula1.png");
      &.formula1 {
        background-image: url("../../../assets/images/backgrounds/intro-background-formula1.png");
      }
    }
  }
  &__hero-container {
    // max-width: #{$dw}px;
    margin: auto;

    &.event {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: inherit;
    }
  }

  &__hero-textcontainer {
    @include breakpoint(sm-only) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &__path-img {
    &.image {
      position: absolute;
      bottom: 0;
      pointer-events: none;
      width: auto;
      height: 118%;
      transform: translateX(-50%);
      left: 34%;
      @include breakpoint(m) {
        left: 43%;
        height: 110%;
        transform: translateX(-50%);
      }
    }
  }

  &__video {
    &-container {
      @include fadeContent;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &-wrapper {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        border-top: 5px solid $white;
        width: 110%;
        left: -5%;
        border-radius: 2px;
        z-index: 1;
        @include breakpoint(m) {
          border-top: 8px solid $white;
        }
      }
    }
    &-row {
      position: relative;
      transform: translateY(
        1px
      ); //cover hairline gap at some screen resolutions due to pixel rounding
    }
  }
  .vidyard-player-container {
    position: relative;
    border-radius: 14px; //sizes inner and outer radii correctly.
    border: $vidBorder--mobile solid $white;
    @include breakpoint(l) {
      border: $vidBorder--desktop solid $white;
    }
  }
  // Specific override for this text lockup
  .intro__top-content {
    .intro__text-content {
      text-align: left;

      @include breakpoint(m) {
        text-align: left;
      }

      @include breakpoint(xs) {
        text-align: start;
        .text__title {
          font-size: 30px;
        }
      }

      @include breakpoint(l) {
        .text__title {
          font-size: 52px;
        }
      }
    }
  }
}
