.vidyard-video-embed {
  &--force-sixteen-nine {
    .vidyard-player-container > div {
      padding-bottom: 56.25% !important; //force vidyard to a 16:9 aspect ratio
    }
  }
}

.vidyard-player-embed {
  width: 100%;
}
