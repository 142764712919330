.recs-video {
  background-image: url("../../../assets/images/bg-bottom-mountains.png");
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;

  &__wrap {
    padding-top: $spacing-20;
    position: relative;
    padding-bottom: $spacing-120;
    @include breakpoint(m) {
      padding-top: $spacing-56;
    }
  }
  &__player-wrap {
    $borderRadius--mobile: 20px;
    $borderRadius--desktop: 35px;
    position: relative;
    .vidyard-video-embed-wrap {
      box-shadow: 0px 24px 48px 8px rgba(0, 20, 45, 0.08);
      border-radius: $borderRadius--mobile;
      @include breakpoint(l) {
        border-radius: $borderRadius--desktop;
      }
    }
    .vidyard-player-container {
      // Weird glitch on Safari when go full screen on the vidyard video.
      // @include fadeContent;
      border: $vidBorder--mobile solid $white;
      border-radius: $borderRadius--mobile;
      background-color: white;
      // fixOverflow rule causes odd corners on Chrome, replacing this by z-index which also solves the same issue
      // @include fixOverflow;
      z-index: 1;

      @include breakpoint(l) {
        border-radius: $borderRadius--desktop;
        border-width: $vidBorder--desktop;
      }
    }
  }
  &__lockup {
    @include fadeContent;
  }
  &__lockup-container {
    position: relative;
    margin-top: $spacing-16;
    margin-bottom: $spacing-56;
  }
  &__butterfly-wrap {
    width: 36px;
    position: absolute;
    right: 30%;
    z-index: 1;
  }
}
// For Microsoft Edge this remove odd corners
iframe {
  border-radius: 1px;
}
