.benefits {
  @include boxShadow;
  @include fadeContent($anim-timing-250);
  padding: $spacing-32 0;
  background-color: $white;
  border-radius: $spacing-16;
  position: relative;
  width: get-vw(22);
  flex-basis: get-vw(22);
  z-index: $z-1;
  margin-top: 24px;

  @include breakpoint(m) {
    padding: $spacing-48 get-vw(1);
    margin-top: 0px;
  }

  @include breakpoint(l) {
    width: auto;
    flex-basis: auto;
  }

  &__wheel {
    position: absolute;
    z-index: 1;
    width: 60px;
    left: 5%;
    top: -36px;

    @include breakpoint(m) {
      width: 126px;
      left: 13%;
      top: -90px;
    }
  }

  &__fly {
    width: 48px;
    height: 43px;
    position: absolute;

    &--first {
      top: -100px;
      display: none;
      @include breakpoint(l) {
        display: block;
      }
    }
  }

  &__bg {
    @include fadeContent;
    padding-top: $spacing-16;
    position: absolute;
    z-index: $z-0;
    right: 0;
    top: -14vw;
    @include breakpoint(m) {
      padding-top: 0;
    }
  }
}
