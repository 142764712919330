main.quiz-container {
  // contain quiz to viewport height
  min-height: calc(100vh - #{$headerMobileHeight});

  @include breakpoint(m) {
    min-height: calc(100vh - #{$headerMobileHeight});
  }

  @include breakpoint(gn) {
    min-height: calc(100vh - #{$headerDesktopHeight});
  }

  //General rules for all questions
  .industries,
  .roles,
  .type,
  .priorities {
    display: flex;
    flex-direction: column;
    height: auto;
    &__container {
      min-height: calc(
        100vh - #{$headerMobileHeight} - 1px - #{$quizFooterMobileHeight}
      );
      @include breakpoint(header-m) {
        min-height: calc(
          100vh - #{$headerMobileHeight} - #{$quizFooterMobileHeight}
        );
      }
      @include breakpoint(l) {
        min-height: calc(
          100vh - #{$headerMobileHeight} - #{$quizFooterDesktopHeight}
        );
      }

      @include breakpoint(gn) {
        min-height: calc(
          100vh - #{$headerDesktopHeight} - #{$quizFooterDesktopHeight}
        );
      }

      &.events {
        min-height: calc(100vh - #{$quizFooterMobileHeight});
      }
    }
    &__outline {
      outline-width: 0;
    }
    &__options {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}

.text {
  &__more {
    @include button-cta;
    padding: 5px 20px;
    border-radius: 40px;
    position: fixed;
    bottom: calc(#{$quizFooterMobileHeight} + 2%);
    left: 50%;
    transform: translateX(-50%);
    cursor: auto;
    @include breakpoint(l) {
      bottom: calc(#{$quizFooterDesktopHeight} + 2%);
    }

    &-arrow {
      width: 10px;
      height: 10px;
      stroke: $white;
      transform: rotate(90deg);
      margin-left: $spacing-8;
      transition: stroke $anim-timing-250 $anim-ease-utility;
    }

    &:hover {
      background-color: $brand-accessible;
      border: 2px solid $brand-accessible;
    }
  }
}
