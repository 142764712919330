.pill {
  &__group {
    max-width: $pillGroupMaxWidth;
    margin: 0 auto;
    padding-bottom: $spacing-24;
    @include breakpoint(m) {
      padding-bottom: $spacing-48;
    }

    & .pill__wrap:only-child {
      max-width: none !important;
    }
  }

  &__wrap {
    margin: $spacing-8;
    @include breakpoint(lt_xl) {
      //needed because max widths are being set as percentages in a style attribute dynamically above xl breakpoint
      max-width: none !important;
    }
  }

  &__icon {
    width: 20px;
    height: 12px;
    margin-left: $spacing-8;
    path {
      stroke: $brand-dark;
      transition: stroke $anim-timing-250 $anim-ease-linear;
    }
  }

  &__group-fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }

  &__group-legend {
    @include visuallyHidden;
  }

  &__radio {
    @include visuallyHidden;
    &:checked + .pill__label {
      background-color: $brand-accessible;
      color: $white;
      .pill__desc {
        color: $white;
      }
    }
    &:hover,
    &:focus + .pill__label {
      background-color: $brand-accessible;
      color: $white;
      .pill__desc {
        color: $white;
      }
    }
  }
  &__label {
    @include pillButton;
    @include bouncyButton;
  }
  &__label_content {
    text-align: center;
  }

  &__label_selected {
    background-color: $brand-accessible;
    color: $white;
  }
  &__desc {
    display: none;
    @include breakpoint(l) {
      @include type-paragraph-2;
      color: $grey;
      display: block;
      transition: color $anim-timing-250 $anim-ease-utility;
    }
  }
}

.--long {
  .pill__label {
    min-height: 70px;
  }
}
