@mixin gradientCommon($cols: 16) {
  border-radius: 50%;
  height: get-vw($cols);
  width: get-vw($cols);
  position: absolute;
  z-index: $z-neg1;
  opacity: 0.3;
}

@mixin gradientCloudsCommon() {
  opacity: 0.55;
  display: none;
  @include breakpoint(m) {
    display: block;
    position: absolute;
    z-index: $z-neg1;
  }
}

.gradient {
  &__clouds {
    &--left {
      @include gradientCloudsCommon();
      top: 20%;
    }
    &--right {
      @include gradientCloudsCommon();
      top: 3%;
    }
  }
  &--right {
    @include gradientCommon();
    background: linear-gradient(
      180deg,
      #c6e9ff 20.83%,
      rgba(198, 233, 255, 0) 100%
    );
    right: -100px;
    top: 0;
    @include breakpoint(m) {
      right: -250px;
      top: -200px;
    }
  }

  &--left {
    @include gradientCommon();
    background: linear-gradient(
      180deg,
      #c6e9ff 20.83%,
      rgba(198, 233, 255, 0) 63.15%
    );
    left: -100px;
    @include breakpoint(m) {
      left: -300px;
    }
  }
  &--large {
    @include gradientCommon(30);
    background: linear-gradient(
      190.01deg,
      #c6e9ff 57.06%,
      rgba(198, 233, 255, 0) 87.37%
    );
  }
  &--xl {
    @include gradientCommon(150);
    background: linear-gradient(
      186.37deg,
      rgba(198, 233, 255, 0) 35.71%,
      #c6e9ff 66.49%,
      rgba(198, 233, 255, 0) 95.59%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
    right: -210%;
    top: -200px;
    opacity: 1;
    @include breakpoint(m) {
      @include gradientCommon(30);
      left: 250px;
      top: -400px;
      right: 0;
      opacity: 1;
    }
  }
}
