.timeline__mobile-progress {
  @include timelineBar($brand-dark);
  transform-origin: left center;
  transform: scaleX(0);
  will-change: transform; //scale change here is what animates to show progress
  &-container {
    display: flex;
    justify-content: space-between;

    @include breakpoint(m) {
      display: none;
    }
  }
  &-item {
    height: 10px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    @include fixOverflow();
    flex-basis: 10%;
    transition: flex-basis $anim-timing-250 $anim-ease-utility;
    &:first-child {
      margin-left: 0;
    }
    &:not(.-active) {
      .timeline__mobile-progress-bg {
        &:hover {
          opacity: 1;
        }
      }
    }
    &.-active {
      flex-basis: 20%;
      .timeline__mobile-progress {
        transform: scaleX(1);
        animation: scaleX $tlDuration $anim-ease-linear forwards; //must match the STAGE_DURATION variable in timeline.js so animation of bar and movement between each stage of the timeline are in sync
      }
      &.-pause {
        .timeline__mobile-progress {
          animation-play-state: paused;
        }
      }
    }
  }
  &-bg {
    @include timelineBar();
    opacity: 0.5;
  }
}

@keyframes scaleX {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
