.priorities {
  width: 100%;
  height: 100%;

  &__app_container {
    @include questionHeaderAndTitle;
  }
  &__outline {
    outline-width: 0;
  }

  &__background-container {
    .background__astro {
      width: px-to-vw(400);
      bottom: 1vw;
      left: 0;
    }
  }
  &__button {
    .pill__label {
      @include buttonAppear;
    }
  }
}
