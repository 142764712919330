.offers {
  margin: 42px 0;
  font-family: $ff-avant--demi;
  color: $genericProduct;

  &-title {
    color: $genericProduct;
    text-wrap: pretty;

    @include type-heading-std;
    font-size: 18px;

    @include breakpoint(l) {
      font-size: 24px;
    }
  }

  @include breakpoint(m) {
    flex-direction: row;
  }

  &-box__container {
    margin: 12px 16px 20px;
    padding: 0px 10px 10px;
  }

  &-tile {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.13);
    padding-bottom: 12px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }

  &-tile:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
  }

  &-link {
    color: #032d60;
    display: block;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: left;
    text-decoration: underline;
    font-family: $ff-sf;
    font-size: 12px;
    font-weight: bolder;
    position: absolute;
    bottom: 20px;

    @include breakpoint(l) {
      font-size: 14px;
    }
  }
}

.banner {
  margin: 96px 0px 24px 0px;
  height: 610px;
  background-image: url("../../../assets/images/backgrounds/banner-mobile.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0px 0px 140px 42px;

  .text-container {
    @include breakpoint(non-desktop) {
      width: 240px;
      flex-basis: auto;
    }
  }

  @include breakpoint(non-desktop) {
    width: 320px;
    flex-basis: auto;
  }

  @include breakpoint(l) {
    height: 500px;
    padding-left: 64px;
    background-image: url("../../../assets/images/backgrounds/banner.png");
    background-size: cover;
  }

  .text__main {
    color: #0d9dda;
  }
}
