.next-btn {
  @include arrowButton;
  opacity: $inactiveOpacity;
  margin-right: get-vw(1);
  pointer-events: none;
  transition: opacity $anim-timing-250 $anim-ease-utility;
  border-radius: 50%;
  margin: 0;

  &:hover {
    cursor: pointer;
    &:before {
      animation-play-state: paused;
    }
  }
  &.-active {
    pointer-events: initial;
    opacity: 1;
    &:hover {
      cursor: pointer;
    }
  }

  &.-animating:before {
    top: unset;
    left: unset;
    animation-name: nextActiveMobile;
    animation-duration: $anim-timing-2000;
    animation-timing-function: $anim-ease-utility;
    animation-iteration-count: infinite;
    @include breakpoint(l) {
      animation-name: nextActive;
    }
  }
}
