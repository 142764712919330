$iconWidth: 80px;

// CELLPHONE
$cellphone_length: 150px;
$cellphone_height: 300px;
$cellphone_depth: 10px;
$cellphone_backgound: 270px;
$cellphone_bezel_padding: 15px;
$cellphone_front_border_radius: 15px;
// cellphone_front_border_radius * arcos(45)
$cellphone_corner_height: 21.2132034356px;

// TIMING
$cellphone_spin_transition: 2s;

$background_color_transition: 0.25s;
$background_color_transition_delay: 0.6s;

$scren_change_transition_delay: 0.6s;
$multipler: 1.5;

@function setSizeWithBreakpoint($device, $value) {
  @if $device ==l {
    $value: $value * $multipler;
  }

  @return $value;
}

.products {
  &__presentation {
    &.box-left {
      background-color: white;
      border-radius: 15px;
      position: absolute;
      top: 170px;
      right: 105px;
      box-shadow: -9px 8px 15px 12px #00163910;

      &--fadein__container {
        margin: 1.2em 2em 1.2em 1.5em;

        @include breakpoint(sm-only) {
          margin: 1em 1.5em 1em 1em;
        }

        &.-active {
          transition: opacity 2s;
          transition-delay: 0.6s;
          position: relative;
          opacity: 1;
          z-index: 1;
        }

        &.-inactive {
          transition: opacity 0.6s;
          position: absolute;
          top: 0px;
          opacity: 0;
        }
      }

      @include breakpoint(sm-only) {
        min-width: 140px;
        // padding: 1em 1.5em 1em 1em;
      }

      @include breakpoint("l") {
        top: 210px;
        left: -14vw;
        max-width: 220px;
      }

      @include breakpoint("xxl") {
        left: -255px;
      }

      &--card {
        width: 100%;
        position: relative;
      }
    }

    &.title {
      @include type-heading-2;
      margin-top: 0;
      margin-bottom: 4px;
      color: $brand-dark;
      padding-right: 15px;

      @include breakpoint(sm-only) {
        font-size: 14px;
        padding-right: 0px;
      }

      @include breakpoint(m) {
        font-size: 20px;
      }
    }

    &.learn__more-link {
      @include type-heading-std;
      font-size: $fs-12;
      letter-spacing: 0px;
      color: $text-secondary;
      display: inline-block;
      margin-top: $spacing-16;
      margin-bottom: 0;
      text-align: left;
      text-decoration: none;
      // @include breakpoint(sm-only) {
      //   font-size: 15px;
      // }
    }

    &.container {
      position: relative;
      margin: 20px;
    }

    &.background {
      position: absolute;
      z-index: -1;
      width: setSizeWithBreakpoint("", $cellphone_backgound);
      height: setSizeWithBreakpoint("", $cellphone_backgound);
      border-radius: 50%;
      background-color: #04e1cb;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      transition: background-color $background_color_transition;
      transition-delay: $background_color_transition_delay;
      animation-timing-function: ease-in-out;

      @include breakpoint("l") {
        width: setSizeWithBreakpoint("l", $cellphone_backgound);
        height: setSizeWithBreakpoint("l", $cellphone_backgound);
      }
    }

    &.scene {
      width: setSizeWithBreakpoint("", $cellphone_length);
      height: setSizeWithBreakpoint("", $cellphone_height);
      perspective: 800px;

      @include breakpoint("l") {
        width: setSizeWithBreakpoint("l", $cellphone_length);
        height: setSizeWithBreakpoint("l", $cellphone_height);
      }

      .cellphone {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transition: transform $cellphone_spin_transition;

        &__face {
          position: absolute;
          background: white;
          font-size: 40px;
          font-weight: bold;
          color: white;
          text-align: center;
        }

        &__front,
        &__back {
          padding: $cellphone_bezel_padding;
          width: setSizeWithBreakpoint("", $cellphone_length);
          height: setSizeWithBreakpoint("", $cellphone_height);
          border-radius: $cellphone_front_border_radius;
          box-shadow: inset 0 1px 4px lightgrey, 0 4px 6px lightgrey;

          @include breakpoint("l") {
            width: setSizeWithBreakpoint("l", $cellphone_length);
            height: setSizeWithBreakpoint("l", $cellphone_height);
          }
        }

        &__right,
        &__left {
          width: setSizeWithBreakpoint("", $cellphone_depth);
          height: setSizeWithBreakpoint("", $cellphone_height) -
            (2 * $cellphone_front_border_radius);
          top: $cellphone_front_border_radius;

          @include breakpoint("l") {
            width: setSizeWithBreakpoint("l", $cellphone_depth);
            height: setSizeWithBreakpoint("l", $cellphone_height) -
              (2 * $cellphone_front_border_radius);
          }
        }

        &__top,
        &__bottom {
          width: setSizeWithBreakpoint("", $cellphone_length) -
            (2 * $cellphone_front_border_radius);
          height: setSizeWithBreakpoint("", $cellphone_depth);
          margin-left: $cellphone_front_border_radius;

          @include breakpoint("l") {
            width: setSizeWithBreakpoint("l", $cellphone_length) -
              (2 * $cellphone_front_border_radius);
            height: setSizeWithBreakpoint("l", $cellphone_depth);
          }
        }

        &__front {
          transform: rotateY(0deg);
        }

        &__back {
          transform: rotateY(180deg)
            translateZ(setSizeWithBreakpoint("", $cellphone_depth));

          @include breakpoint("l") {
            transform: rotateY(180deg)
              translateZ(setSizeWithBreakpoint("l", $cellphone_depth));
          }
        }

        &__right {
          right: 0px;
          transform-origin: top right;
          transform: rotateY(-90deg);
        }

        &__left {
          left: 0px;
          transform-origin: top left;
          transform: rotateY(90deg);
        }

        &__top {
          top: 0px;
          transform-origin: top left;
          transform: rotateX(-90deg);
        }

        &__bottom {
          bottom: 0px;
          transform-origin: bottom left;
          transform: rotateX(90deg);
        }

        &__screen {
          position: absolute;
          top: 0px;

          &__container {
            position: relative;
            width: 100%;
            height: 100%;
          }

          &.-active {
            height: 100%;
            opacity: 1;
            transition-delay: $scren_change_transition_delay;
          }

          &.-inactive {
            height: 0;
            opacity: 0;
            transition-delay: $scren_change_transition_delay;
          }
        }

        &__corner {
          position: absolute;
          background-color: white;
          height: $cellphone_depth;
          width: $cellphone_corner_height;
          box-shadow: inset 5px 0 5px -6px lightgrey,
            inset -5px 0 5px -6px lightgrey;

          @include breakpoint("l") {
            width: $cellphone_corner_height;
            height: setSizeWithBreakpoint("l", $cellphone_depth);
          }

          &.top__left {
            transform-origin: top left;
            top: 0px;
            left: $cellphone_front_border_radius;
            transform: rotateX(-90deg) rotateY(225deg);
            border-radius: 1px 0px 0px 1px;
          }

          &.top__right {
            transform-origin: top right;
            top: 0px;
            right: $cellphone_front_border_radius;
            transform: rotateX(-90deg) rotateY(135deg);
            border-radius: 0px 1px 1px 0px;
          }

          &.bottom__left {
            height: setSizeWithBreakpoint("", $cellphone_depth);
            width: $cellphone_corner_height;
            transform-origin: bottom left;
            bottom: 0px;
            left: $cellphone_front_border_radius;
            transform: rotateX(90deg) rotateY(225deg);
            border-radius: 1px 0px 0px 1px;

            @include breakpoint("l") {
              height: setSizeWithBreakpoint("l", $cellphone_depth);
            }
          }

          &.bottom__right {
            height: setSizeWithBreakpoint("", $cellphone_depth);
            width: $cellphone_corner_height;
            transform-origin: bottom right;
            bottom: 0px;
            right: $cellphone_front_border_radius;
            transform: rotateX(90deg) rotateY(135deg);
            border-radius: 0px 1px 1px 0px;

            @include breakpoint("l") {
              height: setSizeWithBreakpoint("l", $cellphone_depth);
            }
          }
        }
      }

      .popups {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: transform $cellphone_spin_transition;
      }
    }

    &.popup_element {
      position: absolute;
      transition: transform 1s, opacity 1s;

      &.box {
        z-index: 1;
        top: 30px;
        width: 180px;
        height: 240px;

        @include breakpoint(l) {
          width: 270px;
          height: 360px;
        }

        &.-right {
          left: 75px;

          @include breakpoint(l) {
            left: 150px;
          }
        }

        &.-left {
          // left: -150px;
          // For integration left popup
          left: -110px;
          // @include breakpoint(l) {
          //   left: -300px;
          // }
        }

        @keyframes fadeIn {
          from {
            scale: 0;
            opacity: 0;
          }

          to {
            scale: 1;
            opacity: 1;
          }
        }

        &.-active {
          animation: fadeIn $cellphone_spin_transition ease-in-out;
        }

        &.-inactive {
          transition-timing-function: ease-out;
          transform: scale(0);
        }
      }

      &.grass1 {
        width: 105px;
        bottom: 0px;
        left: -86px;
        z-index: -1;

        @include breakpoint(l) {
          width: 130px;
        }
      }

      &.grass2 {
        width: 80px;
        right: -17px;
        bottom: -6px;

        @include breakpoint(l) {
          width: 106px;
        }
      }

      &.grass3 {
        width: 130px;
        right: -75px;
        bottom: -45px;
        z-index: -1;

        @include breakpoint(l) {
          width: 150px;
        }
      }
    }

    &.gif {
      position: absolute;
      transition: all 1s;

      &.box-right {
        z-index: 1;
        top: -45px;
        left: 75px;
        width: 225px;
        height: 300px;

        @include breakpoint(l) {
          top: -75px;
          left: 150px;
          width: 340px;
          height: 450px;
        }
      }
    }
  }

  &__carrusel {
    &.legend {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      height: 50px;
    }

    &.chevron__arrow {
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      color: #90d0fe;
      background: transparent;
      border: none;
      padding: 0;

      @include breakpoint(t) {
        line-height: 25px;
        font-size: 26px;
      }

      &--left {
        margin-right: 1em;
      }

      &--right {
        margin-left: 1em;
      }
    }

    &.dot {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      cursor: pointer;
      border: none;
      transition: 1s;
      padding: 0;
      background-color: #cfe9fe;

      @include breakpoint("t") {
        // width: 25px;
        // height: 25px;
      }

      &.active {
        background-color: #90d0fe;

        // width: 32px;
        // height: 32px;
        // @include breakpoint("t") {
        //   width: 58px;
        //   height: 58px;
        // }
      }

      &--item {
        @include visuallyHidden;
      }

      &--current {
        @include visuallyHidden;
      }
    }

    .dot + .dot {
      margin-left: 1em;
    }
  }

  &__description {
    @include type-paragraph-2;
    color: $grey;
    margin: 0;
    min-width: 22vw;

    @include breakpoint(sm-only) {
      font-size: 8px;
    }

    @include breakpoint(m) {
      font-size: 12px;
    }

    @include breakpoint(t) {
      min-width: 18vw;
      margin-bottom: 0;
    }

    @include breakpoint(l) {
      min-width: auto;
    }
  }

  &__icon-wrapper {
    width: 55px;
    height: 55px;
    position: absolute;
    @include boxShadow;
    border-radius: 100%;
    background-color: $white;
    display: inline-block;
    z-index: 1;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);

    &--fadein__container {
      transition: 1s;

      &.-active {
        opacity: 1;
        transition-delay: 0.6s;
      }

      &.-inactive {
        opacity: 0;
      }
    }

    @include breakpoint(t) {
      width: $iconWidth;
      height: $iconWidth;
    }
  }

  &__icon {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
