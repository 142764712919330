.formula1__box {
  background: white;
  box-shadow: 0px 4px 11px rgba(#000, 0.12);
  display: flex;
  border-radius: 15px;
  width: auto;
  max-width: 400px;

  .logos__container {
    padding: 0.5rem;
    border-radius: 15px 0px 0px 15px;
    background-color: white;
    display: flex;
    align-items: center;

    @include breakpoint(sm-only) {
      min-width: 110px;
    }

    .logos {
      max-height: 200px;
      @include breakpoint(m) {
        width: 130px;
      }
      @include breakpoint(l) {
        width: 110px;
      }
      @include breakpoint(dw) {
        width: 150px;
      }
    }
  }

  .offer__container {
    background: #cfe9fe;
    padding: 18px 12px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 15px 15px 0px;
    @include breakpoint(m) {
      padding: 28px 24px;
    }

    .text {
      @include type-heading-3;
      color: #032d60;
      font-weight: 600;
      font-size: 14px !important;
      line-height: 18px;

      @include breakpoint(l) {
        font-size: 16px !important;
        line-height: 20px;
      }
    }
    .cta {
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      line-height: 28px;
      padding: 8px 12px;
      margin: 10px 0px 0px 0px !important;
      @include breakpoint(l) {
        font-size: 16px;
        padding: 8px 24px;
        align-self: flex-start;
        margin: 20px 0px 0px 0px !important;
      }

      &:hover {
        border: 2px solid #cfe9fe !important;
      }
    }

    .cta__link {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 28px;
      color: #032d60;
      margin: 10px 0px 0px 0px;
      text-decoration: underline;
      align-self: start;
      cursor: pointer;
    }
  }
}

.text__legal {
  font-family: "SalesforceSans-Regular", sans-serif;
  margin: 28px 0px 32px;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #181818;
  text-align: left;

  @include breakpoint(l) {
    margin: 28px auto 32px;
  }

  .rules__link {
    color: #0176d3;
  }
}
.astro__box {
  position: relative;
  @include breakpoint(m) {
    left: 25px;
  }
  @include breakpoint(xl) {
    left: 0px;
  }
}
