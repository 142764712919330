.footer {
  background-color: $white;
  color: $grey;
  transition: opacity $anim-timing-400 $anim-ease-linear;
  display: block;
  width: 100%;
  height: $footerMobileHeight;
  overflow: hidden;

  @include type-paragraph-2;
  padding: $spacing-32 0;

  @include breakpoint(m) {
    height: $footerTabletHeight;
  }

  @include breakpoint(l) {
    height: $footerDesktopHeight;
  }

  &.-inactive {
    display: none;
  }

  &__inner {
    margin: auto;
    max-width: 1020px;
    @include breakpoint(header-l) {
      max-width: 1312px;
    }
  }

  &__copy {
    margin-bottom: $spacing-24;
    margin-left: $spacing-48;

    @include breakpoint(header-m) {
      margin-left: $spacing-24;
    }

    @include breakpoint(header-l) {
      margin-left: $spacing-32;
    }
  }

  &__links-container {
    margin: 0 0 0 $spacing-48;

    @include breakpoint(header-m) {
      margin: 0 0 0 $spacing-24;
    }
    @include breakpoint(header-l) {
      margin: 0 0 0 $spacing-32;
    }
  }

  &__links {
    padding: 0;
    list-style-type: none;
    cursor: pointer;
    margin: 0;
  }
  .cookie {
    border: none;
    background-color: $white;
    font-size: $fs-14;
    cursor: pointer;
    padding: 0;
  }

  &__address {
    margin-top: $spacing-16;
    @include breakpoint(m) {
      margin-top: 0;
    }
  }

  &__link-item {
    display: inline-block;
    margin-right: $spacing-24;
    margin-bottom: $spacing-16;
    @include breakpoint(l) {
      margin-bottom: 0;
    }
  }

  &__logo {
    order: 1;
    margin-top: $spacing-24;
    padding-right: 48px;
    margin-left: 48px;

    @include breakpoint(m) {
      padding-left: 0;
      margin-top: 0;
      margin-left: 0;
      text-align: right;
      order: unset;
    }

    @include breakpoint(header-m) {
      padding-right: 0;
    }
  }

  &__logo-img {
    width: $logo-width-mobile;
    @include breakpoint(l) {
      width: 100%;
      max-width: 120px;
    }
  }

  &__link {
    color: $brand-accessible;
    text-decoration: none;
    transition: color $anim-timing-400 $anim-ease-utility;
    &:hover {
      color: $brand-dark;
    }

    .privacy_choices {
      display: inline-block;
      width: 35px;
      height: 20px;
      margin: 0px 6px -6px 0px;
    }
  }
}
