.tracker-footer-container {
  position: sticky;
  bottom: -($footerMobileHeight);

  @include breakpoint(m) {
    bottom: -($footerTabletHeight);
  }

  @include breakpoint(l) {
    bottom: -($footerDesktopHeight);
  }

  .tracker {
    @include type-paragraph-1;
    background-color: $brand-accessible;
    left: 0;
    z-index: $z-100;
    width: 100%;

    &__mobile {
      //prevents an issue where count can flicker due to tracker animations / device tab bar changes
      position: relative;
      z-index: $z-1;
    }

    &__mobile {
      position: relative;
      z-index: $z-1;
    }

    &__content {
      width: get-vw(20);
    }

    &__inner {
      color: $white;
      text-align: center;
      position: relative;
      padding: $spacing-12 get-vw(1);
      @include breakpoint(l) {
        min-height: $trackerContainerMinHeight;
      }
    }

    &__wrap {
      margin: 0;
      flex-wrap: nowrap;
      @include breakpoint(l) {
        margin: 0;
        min-height: $trackerContentMinHeight;
      }
    }

    &__curve {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-98%);
      width: 100%;
      pointer-events: none;
    }

    &__user-input {
      // @include boxShadow;
      @include inputAppear;
      font-family: $ff-avant--demi;
      font-weight: normal;
      animation-duration: $anim-timing-600;
      display: inline-block;
      vertical-align: middle;
      background-color: $brand-dark;
      color: $brand-dark;
      padding: $spacing-8 $spacing-16;
      min-width: $trackerInputRadius * 2;
      min-height: 40px;
      border-radius: $trackerInputRadius;
      opacity: 0.4;
      margin: 0 12px;
      transition: background-color $anim-timing-400 $anim-ease-utility,
        opacity $anim-timing-400 $anim-ease-utility,
        color $anim-timing-400 $anim-ease-utility;
      &:nth-child(4) {
        .tracker__user-input-copy {
          max-width: 150px;
        }
      }
      &:last-child {
        .tracker__user-input-copy {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          white-space: inherit;
          -webkit-box-orient: vertical;
        }
      }

      @include breakpoint(l) {
        border-radius: $trackerInputRadius--desktop;
      }
      @include trackerTabletLong() {
        margin: 0 $spacing-8;
        padding: $spacing-8;
      }
      &.-active {
        background-color: white;
        opacity: 1;
        .tracker__user-input-copy {
          opacity: 1;
          color: $brand-dark;
        }
      }
    }

    &__user-input-copy {
      max-width: 140px;
      line-height: 136%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: $white;
    }

    &__support-copy {
      opacity: 0.48;
      transition: opacity $anim-timing-400 $anim-ease-utility;
      @include bouncyButton;
      @include inputAppear;
      &.-active {
        opacity: 1;
      }
    }

    .counter {
      color: $white;
    }
  }
}
