.question {
  &__title-wrap {
    margin: $spacing-24 0;
  }
  &__title {
    @include type-heading-2;
    color: $brand-dark;
    text-align: center;
    margin: 0;
  }
  &__subtitle {
    @include type-paragraph-2;
    color: $grey;
    text-align: center;
  }
  &-welcome__message {
    @include type-heading-2;
    color: #0d9dda;
    text-align: center;
    margin: 16px 0px 0px;
  }
}
