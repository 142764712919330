.events {
  &__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &__logo {
    width: 112px;
  }

  &__iframe {
    align-self: stretch;
    height: 70%;
    margin: 0px 40px;
    border: 1px solid #000;
    border-radius: 24px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .button {
      padding-left: 4em;
      padding-right: 4em;
      font-size: 15px;
      border-radius: 30px;
      background: #032d60;
      border-color: #032d60;

      &--send {
        margin-left: 2em;

        &:disabled {
          background: #939393;
          border-color: #939393;
        }
      }
    }
  }

  &__modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal__content {
      background-color: #fefefe;
      width: 50%;

      .form__email {
        padding: 0px 40px 40px 40px;

        .instructions__text {
          font-weight: bold;
        }
        .agreement__text {
          font-style: italic;
          font-size: 12px;
          .hyperlink {
            text-decoration: underline;
            color: blue;
            cursor: pointer;
          }
        }
        .agreement__box {
          height: 300px;
          overflow: scroll;
          border: 1px solid #000;
          padding: 0.5em;
        }
      }

      .row__container {
        display: flex;
        padding: 1em 0em;

        &.header {
          justify-content: flex-end;
          padding: 0px;
        }

        .button__close {
          width: 40px;
          margin: 10px;
          background: transparent;
          border: 0px;
          font-size: 30px;
        }

        .button__form,
        .input__form {
          flex: 1;
          padding: 1em;
          border-radius: 24px;
          display: flex;
          justify-content: center;

          &.last__name {
            margin-left: 1em;
          }
        }
      }
    }
  }

  &__loader {
    border: 3.2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3.2px solid #3498db;
    width: 24px;
    height: 24px;
    -webkit-animation: eventspin 1s linear infinite;
    animation: eventspin 1s linear infinite;
  }
  @-webkit-keyframes eventspin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes eventspin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &-toast__success-background {
    background-color: white !important;
    color: #1a1b1e !important;
    font-family: "SalesforceSans-Regular" !important;
  }
  &-toast__success-progress__bar {
    background: #032d60 !important;
  }

  &-toast__warn-background {
    background-color: white !important;
    color: #1a1b1e !important;
    font-family: "SalesforceSans-Regular" !important;
  }
  &-toast__warn-progress__bar {
    background: #ba0517 !important;
  }
}
