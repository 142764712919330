/********************
**** COLORS
*********************/

// BRAND
$white: #fff;
$brand-primary: #0d9dda;
$brand-accessible: #0176d3;
$brand-dark: #032d60;
$text-primary: #16181a;
$text-secondary: #215ca0;
$text-hover: #001639;
$text-body-primary: #080707;
$text-body-secondary: #58558b;
$text-inverse: $white;
$success: #2e844a;
$accessibile-focus: #005fcc;
$sky-light: #e3f5ff;
$sky-clouds: #e2efee;
$sky-dark: #d3eeff;
$sky-gradient-bottom-solid: #8dd1f3;
$sky-gradient-bottom-match: rgba(#8dd1f3, 0.45);
$light-blue-gradient: linear-gradient(
  180deg,
  #e6f8fe 0%,
  rgba(230, 248, 254, 0) 50%,
  #ffffff 100%
);
$grey: #58575b;
$bg-light: #eaf5f8;
$grey-light: #dddddd;
$grey-lightest: #f0f0f0;
$box-shadow-black: rgba(3, 45, 96, 0.24);

// CHART
$chart-secondary: #0b5cab;

// PRODUCTS
$genericProduct: #032d60;
$sales: #01c3b3;
$service: #e26e8c;
$marketing: #fe9339;
$commerce: #70bf75;
$platform: #8a8ed1;
$integration: #032d60;
$analytics: #ff784f;
$industries: #730394;
$communities: #fcc003;
$employee: #ff784f;
$learning: #0176d3;
$health: #0176d3;
$sustainability: #91db8b;
$heroku: #9050e9;
$partners: #fcc003;
$prodSuccess: #0d9dda;
$slack: #611f69;
$einstein: $genericProduct;
$smb: $genericProduct;
$workcom: $genericProduct;

/********************
**** TYPOGRAPHY
*********************/
//font families
$ff-avant--demi: "AvantGarde-Demi", sans-serif;
$ff-sf: "SalesforceSans-Regular", sans-serif;
$ff-sf--bold: "SalesforceSans-Bold", sans-serif;
//font sizes
$fs-10: 10px;
$fs-12: 12px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-24: 24px;
$fs-30: 30px;
$fs-40: 40px;
$fs-48: 48px;
$fs-56: 56px;
//letter spacing
$ls-std: -0.01em;
$ls-pt6: -0.006em;
$ls-cta: 0.3px;
//line-height
$lh-100: 100%;
$lh-116: 116%;
$lh-130: 130%;
$lh-140: 140%;
$lh-150: 150%;

/********************
**** ANIMATION
*********************/
$anim-timing-50: 50ms;
$anim-timing-100: 100ms;
$anim-timing-250: 250ms;
$anim-timing-400: 400ms;
$anim-timing-500: 500ms;
$anim-timing-600: 600ms;
$anim-timing-800: 800ms;
$anim-timing-1000: 1000ms;
$anim-timing-2000: 2000ms;
$anim-timing-3000: 3000ms;
$anim-timing-4000: 3000ms;
$anim-timing-4500: 4500ms;
$anim-timing-5s: 5s;
$anim-timing-100s: 100s;
$anim-timing-25s: 25s;
$anim-timing-50s: 50s;
$anim-timing-250s: 250s;
$anim-ease-utility: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$anim-ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$anim-ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$anim-ease-expressive: cubic-bezier(0.45, 0.05, 0.5, 0.1);
$anim-ease-linear: linear;
$anim-translate-y: 16px;
$anim-translate-y-lg: 32px;

/********************
**** SPACING
*********************/
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-72: 72px;
$spacing-80: 80px;
$spacing-88: 88px;
$spacing-96: 96px;
$spacing-120: 120px;
$spacing-144: 144px;
$spacing-192: 192px;
$spacing-224: 224px;
$spacing-280: 280px;

$header-height-device: 100px;
$header-height: 116px;
$header-height-scrolled: 76px;
$logo-width-mobile: 94px;
$logo-width-scrolled: 60px;
$logo-width: 120px;
$header-shadow: 0px 4px 24px rgba(3, 46, 97, 0.16);
$tracker-height-mobile: 98px;
$tracker-height: 120px;
$cta-button-spacing: 13px;

/********************
**** BREAKPOINTS
*********************/
$device-s: 568px;
$device-m: 768px;
$device-t: 1024px;
$device-l: 1200px;
$device-dw: 1440px;
$device-xl: 1600px;
$device-xxl: 1818px;
$device-gn: 1280px; // Breakpoint for salesforce digital global nav
$dw: 1440; //design width in px of sketch files, useful for maintaining proportion of elements in viewport widths (in conjunction with the `px-to-vw` mixin, for example)
$dw-mobile: 375;
$max-width: 2016; //seemingly random number but this allows the max width of a grid container with 2 columns of padding left and right to be 1680.

/********************
**** Z-INDEX
*********************/
$z-neg1: -1;
$z-0: 0;
$z-1: 1;
$z-10: 10;
$z-100: 100;
$z-101: 101;

/********************
**** MISC
*********************/
$arrowButtonWidth: 60px;
$brandBorderRadius: 18px;
$buttonBorderRadius: 10px;
$pillGroupMaxWidth: $dw * 1px;
$pillBorderRadius: $spacing-16;
$pillBorderRadius--desktop: $spacing-24;
$trackerInputRadius: 30px;
$trackerInputRadius--desktop: 60px;
$trackerContentMinHeight: 60px;
$trackerContainerMinHeight: $tracker-height;
$inactiveOpacity: 0.5;
$hoverOpacity: 0.8;
$buttonOpacity: 0.95;
$parallaxScaleFactor: 1.1; //how much we scale the parallax bg so that it never reveals its edges within the browser window as it shifts around.
$pillBgColor: rgba($white, $buttonOpacity);
$tlItemWidth: 100%; //timeline item width
$tlItemMaxHeight: 280px;
$tlDuration: 7s; //should match the TIMELINE_DURATION JS variable
$vidBorder--mobile: 8px;
$vidBorder--desktop: 13px;
$focusRectWidth: 2px;
$footerMobileHeight: 416px;
$footerTabletHeight: 246px;
$footerDesktopHeight: 193px;
$headerMobileHeight: 56px;
$headerDesktopHeight: 96px;
$quizFooterMobileHeight: 84px;
$quizFooterDesktopHeight: 120px;
