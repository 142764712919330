.share {
  @include fadeContent;
  padding: $spacing-32;
  background-image: url("../../../assets/images/share_dotted_line.png");
  background-color: white;
  background-repeat: repeat, no-repeat;
  background-size: 100%;
  border-radius: $brandBorderRadius;
  @include boxShadow("light");

  &__eyebrow {
    @include eyebrow;
    margin: 0 auto;
  }

  &__heading {
    @include type-heading-3;
    margin-top: $spacing-24;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-16;
    color: $brand-dark;
  }

  &__body {
    @include type-paragraph-2;
    margin: 0;
    color: $grey;
  }

  &__img {
    width: 60%;
    max-width: 60%;
    margin: 0 auto;
  }

  &__button {
    @include button-cta();
    padding-left: $spacing-24;
    padding-right: $spacing-24;
    position: relative;
    z-index: $z-10;
    transition: background-color $anim-timing-250 $anim-ease-utility;
    &--success {
      pointer-events: none;
    }
    &:hover {
      @include breakpoint(s) {
        .share__button--hover {
          display: none;
        }
      }
      @include breakpoint(l) {
        .share__button--hover {
          opacity: 1;
        }
      }
    }
    &--hover {
      @include button-cta();
      position: absolute;
      top: calc(-100% - 20px);
      left: 50%;
      transform: translatex(-50%);
      background-color: $white;
      color: $brand-dark;
      border-color: $brand-dark;
      opacity: 0;
      min-width: 130px;
      pointer-events: none;
      transition: opacity $anim-timing-250 $anim-ease-utility;
      @include breakpoint(l) {
        left: calc(100% + 10px);
        top: -2px;
        transform: none;
      }
      &:before {
        @include leftTriangle($color: $white);
        top: calc(100% - 2px);
        z-index: $z-1;
        @include breakpoint(l) {
          left: -7px;
        }
      }
      &:after {
        @include leftTriangle($color: $brand-dark);
        top: calc(100% + 2px);
        @include breakpoint(l) {
          left: -10px;
        }
      }
    }
  }
  &__success {
    @include button-cta();
    top: calc(-100% - 8px);
    left: 50%;
    transform: translatex(-50%);
    pointer-events: none;
    background-color: $success;
    border-color: $success;
    position: absolute;
    animation: opacity $anim-timing-400 $anim-ease-utility forwards;
    @include breakpoint(l) {
      left: calc(100% + #{$spacing-8});
      top: 0;
      transform: none;
    }

    &:before {
      @include leftTriangle($color: $success);
      top: calc(100% + 2px);
      @include breakpoint(l) {
        left: -8px;
      }
    }
  }

  &__url-input {
    position: absolute;
    left: -10000%; //hides input off screen
    opacity: 0;
    pointer-events: none;
  }

  &__url-label {
    @include visuallyHidden;
  }

  &__copy-input {
    position: relative;
    display: inline-block;
    margin-top: $spacing-8;
    @include breakpoint(m) {
      margin-top: $spacing-24;
    }
  }
}
