// Media query mixin. Mobile-first opinionated.
@mixin breakpoint($size) {
  @if $size == m {
    @media (min-width: $device-m) {
      @content;
    }
  } @else if $size == t {
    @media (min-width: $device-t) {
      @content;
    }
  } @else if $size == l {
    @media (min-width: $device-l) {
      @content;
    }
  } @else if $size == gn {
    @media (min-width: $device-gn) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: $device-s) {
      @content;
    }
  } @else if $size == dw {
    @media (min-width: $device-dw) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: $device-xl) {
      @content;
    }
  } @else if $size == xxl {
    @media (min-width: $device-xxl) {
      @content;
    }
  } @else if $size == sm-only {
    @media (max-width: ($device-m - 1)) {
      @content;
    }
  } @else if $size == non-desktop {
    @media (max-width: ($device-l - 1)) {
      @content;
    }
  } @else if $size == lt_xl {
    @media (max-width: ($device-xl - 1)) {
      @content;
    }
  } @else if $size == between-s-and-m {
    @media (min-width: $device-s) and (max-width: ($device-m - 1)) {
      @content;
    }
  } @else if $size == ipad_pro {
    @media (min-width: $device-m) and (min-height: $device-l) and (max-width: $device-l) and (max-height: $device-xl) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  } @else if $size == header-m {
    //for matching salesforce.com header breakpoints
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $size == header-l {
    //for matching salesforce.com header breakpoints
    @media (min-width: 1312px) {
      @content;
    }
  } @else if $size == touchscreens {
    @media (hover: none) and (pointer: coarse) {
      @content;
    }
  }
}

// Specifically fix some issues with long text in the tracker before mobile break;
@mixin trackerTabletLong() {
  @media (max-width: 1300px) and (min-width: 1200px) {
    @content;
  }
}

// Converts pixels to viewport width, given the size in pixels and an overall context width to use in calculating the ratio.
// Context defaults to the canvas width at which the site was designed in Figma ($dw: 1440)
// Usage example: Astro is designed at 250px and needs to scale responsively: `width: px-to-vw(250)`
// or if it were mobile: width: px-to-vw(250,$dw-mobile);
@function px-to-vw($target, $context: $dw) {
  $vw-context: #{$context} * 0.01;
  @return #{($target / $context * 100) * 1vw};
}

@function vw-to-px($target, $context: $dw) {
  $vw-context: #{$context} * 0.01;
  @return #{($target / $context * 100) * 1px};
}

/********************
**** RESETS
*********************/
@mixin visuallyHidden() {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin button-reset() {
  -webkit-font-smoothing: antialiased;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  &:focus {
    @include a11y-focus();
  }
}

/********************
**** LAYOUT
*********************/
@mixin questionHeaderAndTitle() {
  @include breakpoint(l) {
    .header {
      position: absolute;
    }
    .question__title-wrap {
      margin-top: 32px;
      max-width: 80vw;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/********************
**** BUTTONS
*********************/
@mixin a11y-focus() {
  /* suppress focus ring on form controls for mouse users */
  [data-whatinput="mouse"] *:focus,
  [data-whatintent="mouse"] *:focus {
    outline: none;
  }
  [data-whatinput="keyboard"] *:focus {
    outline: $focusRectWidth solid $accessibile-focus;
    &:before {
      outline: $focusRectWidth solid $white;
    }
  }
}

@mixin button-default() {
  @include button-reset();
  height: 48px;
  padding: 0 $spacing-24;
  border: 2px solid currentColor;
  border-radius: $buttonBorderRadius;
}

@mixin button-cta($type: "default") {
  @include button-reset();
  @include type-paragraph-cta;
  padding: $cta-button-spacing $spacing-24;
  border-radius: 4px;
  transition: background-color $anim-timing-400 $anim-ease-utility,
    color $anim-timing-400 $anim-ease-utility,
    border-color $anim-timing-400 $anim-ease-utility;
  @if $type == "reverse" {
    background-color: white;
    color: $brand-dark;
    border: 2px solid $white;
    &:hover {
      background-color: $brand-dark;
      color: $white;
    }
  } @else if $type == "clear" {
    background-color: transparent;
    color: $brand-accessible;
    border: 2px solid $brand-accessible;
    &:hover {
      background-color: $brand-dark;
      color: $white;
      border-color: $brand-dark;
    }
  } @else if $type == "clear-reverse" {
    background-color: transparent;
    color: $white;
    border: 2px solid $white;
    &:hover {
      background-color: $white;
      color: $brand-dark;
    }
  } @else if $type == "blue-on-dark" {
    background-color: $brand-accessible;
    color: $white;
    border: 2px solid $brand-accessible;
    &:hover {
      background-color: $brand-dark;
      border: 2px solid $white;
    }
  } @else {
    background-color: $brand-accessible;
    color: $white;
    border: 2px solid $brand-accessible;
    &:hover {
      background-color: $brand-dark;
      border: 2px solid $brand-dark;
    }
  }
}

@mixin arrowButton($reverse: false) {
  @include button-reset;
  position: relative;
  &:before {
    content: "";
    background-color: $white;
    border-radius: 100%;
    width: $arrowButtonWidth;
    height: $arrowButtonWidth;
    top: 0;
    left: 0;
    transition: background-color $anim-timing-250 $anim-ease-utility;
    display: block;
  }
  svg {
    stroke: $brand-accessible;
    position: absolute;
    z-index: $z-1;
    transition: stroke $anim-timing-250 $anim-ease-utility;
    width: 20px;
    height: 18px;
  }
  &:hover {
    svg {
      stroke: $white;
    }
    &:before {
      background-color: $brand-dark;
    }
  }
  @if $reverse == true {
    &:before {
      background-color: $brand-dark;
    }
    svg {
      stroke: $white;
    }
  }
  &:focus {
    &:before {
      @include a11y-focus();
    }
  }
}

@mixin arrowButtonInline {
  @include arrowButton;
  width: $arrowButtonWidth;
  height: $arrowButtonWidth;

  svg {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin boxShadow($type: "light") {
  @if $type == "light" {
    box-shadow: 0px 18px 37px rgba(0, 0, 0, 0.2);
  } @else {
    box-shadow: 0px $spacing-8 $spacing-16 $box-shadow-black;
  }
}

@mixin leftTriangle($color) {
  content: "";
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid $color;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 0;
  transition: opacity $anim-timing-250 $anim-ease-utility;
  @include breakpoint(l) {
    border-top: 3px solid transparent;
    border-right: 6px solid $color;
    border-bottom: 3px solid transparent;
    top: 50%;
    transform: translateY(-50%);
  }
}

/********************
**** TYPE
*********************/
@mixin antialias {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin type-heading-std() {
  @include antialias;
  font-family: $ff-avant--demi;
  font-size: $fs-24;
  letter-spacing: $ls-std;
  line-height: $lh-116;
  font-weight: normal;
}

@mixin type-heading-1() {
  @include type-heading-std;
  @include responsive-text($fs-30, $fs-56);
}

@mixin type-heading-2() {
  @include type-heading-std;
  @include responsive-text($fs-24, $fs-40);
}

@mixin type-heading-3() {
  @include type-heading-std;
  @include responsive-text($fs-20, $fs-24);
}

@mixin type-heading-4() {
  @include type-heading-std;
  font-size: $fs-16;
  letter-spacing: $ls-pt6;
  @include breakpoint(l) {
    line-height: $lh-130;
  }
}

@mixin type-heading-5() {
  @include type-heading-std;
  @include responsive-text($fs-16, $fs-30);
}

@mixin type-paragraph-1($weight: "regular") {
  @if $weight == "bold" {
    font-family: $ff-sf--bold;
    font-weight: bold;
  } @else {
    font-family: $ff-sf;
  }
  font-size: $fs-14;
  line-height: $lh-140;
  @include breakpoint(m) {
    font-size: $fs-16;
    line-height: $lh-150;
  }
}

@mixin type-paragraph-2() {
  font-family: $ff-sf;
  font-size: $fs-14;
  line-height: $lh-150;
}

@mixin type-paragraph-cta() {
  font-family: $ff-sf;
  font-size: $fs-14;
  letter-spacing: $ls-cta;
}

@mixin eyebrow {
  @include type-heading-std;
  letter-spacing: 0.02em;
  font-size: $fs-12;
  line-height: $lh-130;
  color: $brand-dark;
  text-transform: uppercase;
}

// responsive typography function to strip unit
@function strip-unit($number) {
  @if (type-of($number) == "number" and not unitless($number)) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// responsive typography mixin that interpolates
// type size between min and max
@mixin responsive-text(
  $min-font-size,
  $max-font-size,
  $enableLineHeight: false
) {
  $device-font-scale: 1440px;
  font-size: $min-font-size;
  @if $enableLineHeight {
    line-height: $min-font-size;
  }

  @include breakpoint(m) {
    font-size: calc(
      #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
        ((100vw - #{$device-m}) / #{strip-unit($device-font-scale - $device-m)})
    );
    @if $enableLineHeight {
      line-height: calc(
        #{$min-font-size} +
          #{strip-unit($max-font-size - $min-font-size)} *
          (
            (100vw - #{$device-m}) /
              #{strip-unit($device-font-scale - $device-m)}
          )
      );
    }
  }
  @media (min-width: ($device-font-scale)) {
    font-size: $max-font-size;
    @if $enableLineHeight {
      line-height: $max-font-size;
    }
  }
}

/********************
**** COMPONENTS
*********************/

@mixin timelineBar($color: $brand-dark) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color;
}

/********************
**** ANIMATIONS
*********************/
@mixin bouncyButton {
  animation-duration: $anim-timing-600;
  animation-timing-function: $anim-ease-out-back;
  animation-fill-mode: forwards;
  transform: scale(0);
}

@mixin inputAppear {
  animation-name: inputAppear;
}

@mixin buttonAppear {
  animation-name: buttonAppear;
}

@mixin pillButton {
  @include type-heading-3;
  flex-direction: column;
  min-height: 60px;
  max-height: 200px;
  border-radius: $pillBorderRadius;
  padding: $spacing-16 $pillBorderRadius;
  width: 100%;
  color: $brand-dark;
  background-color: $pillBgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: background-color $anim-timing-250 $anim-ease-utility,
    color $anim-timing-250 $anim-ease-utility;

  @include breakpoint(m) {
    height: 7vh;
  }

  @include breakpoint(l) {
    height: 7vw;
    border-radius: $pillBorderRadius--desktop;
    padding: $spacing-24 $pillBorderRadius--desktop;
  }

  &:hover,
  &:focus {
    background-color: $brand-accessible;
    color: $white;
    cursor: pointer;

    .pill__desc {
      color: $white;
    }
    .pill__icon path {
      stroke: $white;
    }
  }
}

@mixin animFade($animName: fadeIn, $delay: 0, $animCurve: $anim-ease-utility) {
  animation: $animName $anim-timing-400 $animCurve forwards;
  animation-delay: $delay;
  @media (prefers-reduced-motion) {
    animation: none;
    opacity: 1;
  }
}

@mixin fadeContent($delay: 0, $animCurve: $anim-ease-utility) {
  .-active & {
    @include animFade(fadeIn, $delay, $animCurve);
  }
  & {
    opacity: 0;
  }
}

@mixin fadeScaleContent($delay: 0, $animCurve: $anim-ease-utility) {
  .-active & {
    @include animFade(fadeScaleIn, $delay, $animCurve);
  }
  & {
    opacity: 0;
  }
}
/********************
**** FIXES
*********************/

@mixin fixOverflow {
  //solves issue where safari does not hide the children's overflow with respect to the border radius here
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

@mixin fixFlicker {
  //solves issue where items which have been given complex transforms tend to flicker in ios / safari
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
}
