.type {
  width: 100%;
  height: 100%;
  &__app_container {
    @include questionHeaderAndTitle;
  }
  &__business {
    outline-width: 0;
  }

  &__background-container {
    .background__astro {
      width: px-to-vw(623);
      top: px-to-vw(-43);
      right: px-to-vw(-250);
    }
  }
  &__pillgroup_row {
    .--long .pill__label {
      @include breakpoint(l) {
        height: 7vw;
      }
    }
  }
}
