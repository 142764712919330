// WHEEL VARS
$wheel-left: 50%;
$wheel-top: 50%;
$items: 12;
$easing: cubic-bezier(0.65, 0, 0.35, 1);
$easing-hover: cubic-bezier(0.4, 0, 0.4, 1);

//ORIG
$item-label-spacing: 4px; //distance between circle and its label
$item-border: 2;
$item-border-width: $item-border * 1px;
$item-inactive-opacity: 0.25;
$box-shadow-offset: 0px;
$box-shadow-blur: 0px;
$box-shadow-opacity: 0;

//NEW
$center-radius: 100;
$center-width-vw: px-to-vw($center-radius * 2, $dw-mobile);
$outer-radius: 150;
$item-size: 30;
$item-size-vw: px-to-vw($item-size, $dw-mobile);
$item-size-max: 50;
$item-translate-vw: px-to-vw($outer-radius/1.2, $dw-mobile);
$wheel-width: ($outer-radius + ($item-size/2) + $item-border) * 2;
$wheel-width-vw: px-to-vw($wheel-width, $dw-mobile);
$wheel-width-max: 440;
$wheel-width-max-px: $wheel-width-max * 1px;
$item-width-max: $item-size-max * 1px;

@function centerMaxPx($device) {
  @if $device == m {
    $wheel-width-max: 500 !global;
  } @else if $device == l {
    $wheel-width-max: 500 !global;
  } @else {
    $wheel-width-max: 440 !global;
  }
  @return ($wheel-width-max * (($center-radius * 2.5) / $dw-mobile)) * 1px;
}

@function itemTranslateMax($device) {
  @if $device == m {
    $wheel-width-max: 460 !global;
  } @else if $device == l {
    $wheel-width-max: 460 !global;
  } @else {
    $wheel-width-max: 440 !global;
  }
  @return ($wheel-width-max/2) - ($item-size-max/2) - $item-border;
}

/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
@mixin on-circle() {
  padding: 0;
  list-style: none;

  .wheel__list-item {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: flex-end;
    top: calc(50% - #{$item-size-vw/2});
    left: calc(50% - #{$item-size-vw/2});
    width: $item-size-vw;
    height: $item-size-vw;
    $angle: (
      360 / $items
    ); // change this line if you want a non-uniform distribution
    $rot: -90; // change this line if you want a non-uniform distribution

    @for $i from 1 through $items {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($item-translate-vw)
          rotate($rot * -1deg);

        @include breakpoint(between-s-and-m) {
          transform: rotate($rot * 1deg) translate(170px) rotate($rot * -1deg);
        }
        @include breakpoint(m) {
          //dont' translate items by viewport widths any further
          transform: rotate($rot * 1deg) translate(itemTranslateMax("m") * 1px)
            rotate($rot * -1deg);
          width: $item-width-max;
          height: $item-width-max;
          top: calc(50% - #{$item-width-max/2});
          left: calc(50% - #{$item-width-max/2});
        }
      }

      $rot: $rot + $angle;
    }
  }
}

@mixin icon-titles {
  @include type-paragraph-2;
  color: $text-body-primary;
  font-family: $ff-avant--demi;
  font-weight: normal;
  font-size: $fs-10;
}

.wheel {
  position: relative;
  z-index: $z-1;
  // Causing wheel not being centered
  // width: $wheel-width-vw;
  height: $wheel-width-vw;
  max-width: $wheel-width-max-px;
  max-height: $wheel-width-max-px;
  background-color: white;
  border-radius: 50%;
  @include breakpoint(m) {
    $wheel-width-max-px: 520px;
    max-width: $wheel-width-max-px;
    max-height: $wheel-width-max-px;
  }
  @include breakpoint(l) {
    $wheel-width-max-px: 520px;
    max-width: $wheel-width-max-px;
    max-height: $wheel-width-max-px;
  }
  &--recommendations {
    margin: $spacing-32 auto $spacing-48 auto;
    @include breakpoint(m) {
      margin: 0px auto $spacing-72 auto;
    }
    @include breakpoint(l) {
      margin: $spacing-32 auto $spacing-48 auto;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: $z-100;

    &.-active {
      //when wheel is in view
      .wheel {
        &__center {
          .lazyload-wrapper {
            transform: scale(1);
          }
          .image {
            opacity: 1;
          }
        }
        &__pip {
          transform: scale(1);
        }
        &__product-name {
          opacity: 1;
        }
        &__icon-wrapper {
          &:before {
            @include breakpoint(m) {
              border-width: $item-border-width;
            }
          }
        }
        &__list-item {
          @for $i from 1 through $items {
            &:nth-child(#{$i}) {
              .wheel {
                &__pip,
                &__product-name {
                  transition-delay: $i * 50ms;
                }
                &__icon-wrapper:before,
                &__icon-wrapper {
                  transition-delay: $anim-timing-800 + ($i * 50ms);
                }
              }
            }
          }
          &:not(.deactivate) {
            .wheel__icon-wrapper {
              transform: scale(1);
            }
          }
        }
      }
    }
  }

  &__list {
    position: absolute;
    margin: 0 0 2rem 0;
    z-index: $z-100;
    @include on-circle();
    @include breakpoint(sm-only) {
      margin: 0;
    }
  }

  &__list-item {
    &.deactivate {
      opacity: 0.35;
      .wheel {
        &__icon-wrapper:before {
          border: 0;
          box-shadow: 0px $box-shadow-offset $box-shadow-blur
            rgba(0, 0, 0, $box-shadow-opacity);
        }
      }
    }
  }

  &__product-name {
    @include icon-titles;
    text-align: center;
    pointer-events: none;
    opacity: 0; //starting opacity
    transition: opacity $anim-timing-250 $easing;
    transform: translateY(calc(100% + #{$item-label-spacing}));
    @include fixFlicker;
    display: none;
    min-width: 80px;
    @include breakpoint(m) {
      display: block;
    }
  }

  &__pip {
    position: absolute;
    z-index: $z-10;
    transition: transform $anim-timing-400 $anim-ease-out-back,
      opacity $anim-timing-250 $anim-ease-linear;
    @include fixFlicker;
    // background-color: $white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0);

    &.deactivate {
      background-color: white;
    }

    &--position-0 {
      color: $sales;
      background-color: #0bc3ba1f;
    }

    &--position-1 {
      color: $service;
      background-color: #e26e8c1f;
    }

    &--position-2 {
      color: $marketing;
      background-color: #fe93391f;
    }

    &--position-3 {
      color: $commerce;
      background-color: #70bf751f;
    }

    &--position-4 {
      color: $analytics;
      background-color: #ff784f1f;
    }

    &--position-5 {
      color: $integration;
      background-color: #032d601f;
    }

    &--position-6 {
      color: $platform;
      background-color: #898ece1f;
    }

    &--position-7 {
      color: $slack;
      background-color: #611f691f;
    }

    &--position-8 {
      color: $sustainability;
      background-color: #91db8b1f;
    }

    &--position-9 {
      color: $industries;
      background-color: #7003941f;
    }

    &--position-10 {
      color: $partners;
      background-color: #fcc0051f;
    }

    &--position-11 {
      color: $prodSuccess;
      background-color: #0d9dda1f;
    }
  }

  &__icon-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform $anim-timing-250 $anim-ease-out-back;
    @include fixFlicker;

    &:before {
      content: "";
      border: 2px solid currentColor;
      box-shadow: 0px $box-shadow-offset $box-shadow-blur
        rgba(0, 0, 0, $box-shadow-opacity);
      @include breakpoint(m) {
        border-width: 0;
        box-shadow: 0px $box-shadow-offset * 2 $box-shadow-blur * 2
          rgba(0, 0, 0, $box-shadow-opacity);
      }
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      transition: border-width $anim-timing-400 $anim-ease-out-back,
        box-shadow $anim-timing-250 $anim-ease-linear;
      @include fixFlicker;
    }
  }

  &__img {
    display: block;
    height: auto;
    max-width: 100%;
    user-select: none;
    width: 59%;
    transition: transform $anim-timing-600 $easing;
    @include fixFlicker;
  }
  &__center {
    position: absolute;
    left: 50%;
    top: 50%;
    // width: $center-width-vw;
    // height: $center-width-vw;
    // Not using the function but using the same return value with a little tweaking
    width: 50.33333333vw;
    height: 53.33333333vw;
    transform: translate(-50%, calc(-50% + 6px));
    // max-width: centerMaxPx("");
    // max-height:centerMaxPx("");
    // Not using the function but using the same return value with a little tweaking
    max-width: 253.33333px;
    max-height: 283.33333px;

    @include breakpoint(m) {
      // max-width: centerMaxPx("m");
      // max-height:centerMaxPx("m");
      // Not using the function but using the same return value with a little tweaking
      max-width: 303.333333px;
      max-height: 333.333333px;
      transform: translate(-50%, calc(-50%));
    }

    .lazyload-wrapper {
      width: 100%;
      transform: scale(0);
      transition: transform $anim-timing-400 $anim-ease-out-back;
      @include fixFlicker;
    }
    .image {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: contain;
      transform-origin: bottom center;
      opacity: 0;
      transition: opacity $anim-timing-400 $anim-ease-linear;
    }
  }
  &.mode--explode .wheel__list-item {
    //appears as last stage of timeline
    $mobile-icon-scale-factor: 0.75;
    &.deactivate {
      .wheel__pip {
        transform: scale(0);
      }
      .wheel__product-name {
        opacity: 0 !important;
      }
    }
    &:not(.deactivate) {
      transition: transform $anim-timing-400 $anim-timing-400
        $anim-ease-out-back;
      @include fixFlicker;

      .wheel__pip,
      .wheel__product-name {
        transition-delay: $anim-timing-400;
      }

      .wheel__product-name {
        font-size: $fs-16;
        @include breakpoint(sm-only) {
          opacity: 0;
        }
      }
      &:nth-of-type(1) {
        $rot: 130deg;
        $scale: 2;

        transform: rotate(-$rot) translate($item-translate-vw) rotate($rot);

        @include breakpoint(between-s-and-m) {
          transform: rotate(-$rot) translate(210px) rotate($rot);
        }

        @include breakpoint(l) {
          transform: rotate(-$rot) translate(itemTranslateMax("l") * 1px)
            rotate($rot);
        }
        .wheel__pip {
          transform: scale($scale * $mobile-icon-scale-factor);
          @include breakpoint(m) {
            transform: scale($scale);
            margin-right: 65px;
          }
        }
        .wheel__product-name {
          transform: translateY(
            calc(
              (#{$scale} * 100%) + #{$item-border-width * $scale} + #{$fs-16} +
                #{$item-label-spacing}
            )
          );
          margin-right: 65px;
        }
      }

      &:nth-of-type(2) {
        $rot: 60deg;
        $scale: 3;

        transform: rotate(-$rot) translate($item-translate-vw) rotate($rot);

        @include breakpoint(between-s-and-m) {
          transform: rotate(-$rot) translate(210px) rotate($rot);
        }

        @include breakpoint(l) {
          transform: rotate(-$rot) translate(itemTranslateMax("l") * 1px + 30px)
            rotate($rot);
        }

        .wheel__pip {
          transform: scale($scale * $mobile-icon-scale-factor);
          @include breakpoint(m) {
            transform: scale($scale);
            margin-bottom: 35px;
          }
        }
        .wheel__product-name {
          transform: translateY(
            calc(
              (#{$scale} * 100%) + #{$item-border-width * $scale} + #{$fs-16} +
                #{$item-label-spacing}
            )
          );
          margin-bottom: 35px;
        }
      }
      &:nth-of-type(3) {
        $rot: -45deg;
        $scale: 2.5;

        transform: rotate(-$rot) translate($item-translate-vw) rotate($rot);

        @include breakpoint(between-s-and-m) {
          transform: rotate(-$rot) translate(210px) rotate($rot);
        }

        @include breakpoint(l) {
          transform: rotate(-$rot) translate(centerMaxPx("l") / 2) rotate($rot);
        }

        .wheel__pip {
          transform: scale($scale * $mobile-icon-scale-factor);
          @include breakpoint(m) {
            transform: scale($scale);
          }
        }
        .wheel__product-name {
          transform: translateY(
            calc(
              (#{$scale} * 100%) + #{$item-border-width * $scale} + #{$fs-16} +
                #{$item-label-spacing}
            )
          );
        }
      }
    }
  }
}

.timeline {
  .wheel__list-item {
    &.deactivate {
      .wheel {
        &__product-name,
        &__img {
          opacity: $item-inactive-opacity;
        }
      }
    }
  }
}
