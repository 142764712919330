.restart {
  &__bg {
    background-size: cover;
    background-position: bottom;
    padding: $spacing-48 0 $spacing-96 0;
    @include breakpoint(m) {
      background-position: center;
      padding: $spacing-96 0;
    }
  }

  &__cta {
    @include button-cta;
  }
}
