$cardPadding: get-vw(0.65);
.card {
  position: relative;
  padding-top: $spacing-24;
  margin: 0 get-vw(2);
  &:first-child {
    padding-top: 0;
  }

  @include breakpoint(m) {
    padding-top: $spacing-32;
    margin: 0;
    &--0 {
      padding-top: 0;
    }
    &--last {
      margin-right: 0;
    }
  }

  @include breakpoint(l) {
    width: get-vw(4.5);
    flex-basis: get-vw(4.5);
    max-width: 300px;
    padding-top: 0;
    padding-right: $cardPadding;
    margin-right: $cardPadding;

    &:after {
      content: "";
      height: 75%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background-color: $grey-light;
      width: 1px;
    }

    &--last {
      margin-right: -$cardPadding;
      &:after {
        display: none;
      }
    }

    &--total-3 {
      @include breakpoint(l) {
        max-width: 450px;
      }
    }

    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .card {
      width: 37.75vw;
      flex-basis: 37.75vw;
      max-width: 600px;
    }

    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ .card {
      width: 24.75vw;
      flex-basis: 24.75vw;
    }
  }

  &__title {
    @include type-heading-std;
    color: $brand-dark;
    margin-top: 0;
    margin-bottom: $spacing-16;
  }

  &__desc {
    @include type-paragraph-2;
    color: $grey;
    margin: 0;
  }
}
