.recs {
  position: relative;
  overflow: hidden;
  &.-inactive {
    height: 100vh; //prevents scrolling past interstitial
  }

  &__hero {
    position: relative;
    z-index: $z-1;
    overflow: hidden;

    .text__title {
      font-size: calc(24px + 1.8vw);
      margin: 24px auto 24px;
      @include breakpoint("xxl") {
        font-size: 61px;
      }
    }

    .text__body {
      color: #181818;
      font-size: 16px;
      text-align: center;
      @include breakpoint(m) {
        margin: 0px 64px 32px;
      }
      @include breakpoint(l) {
        font-size: 19px;
        line-height: 160%;
        text-align: left;
        margin: 0px 100px 0px 0px;
      }
    }

    &-cta {
      @include type-heading-std;
      color: $white;
      text-align: center;
      @include breakpoint(l) {
        transform: translateY($spacing-96);
      }
    }

    &-wheel {
      @include fadeScaleContent($anim-timing-400);
      margin-bottom: $spacing-192;

      @include breakpoint(xxl) {
        position: absolute;
        right: -4.4vw; // bump 1 col for very large screens
      }
    }

    &-git {
      width: 130px;
      height: 130px;
      right: 0;
      position: relative;
      @include breakpoint(m) {
        width: 200px;
        height: 200px;
      }
      @include breakpoint(t) {
        width: 250px;
        height: 250px;
      }
      @include breakpoint(l) {
        width: 240px;
        height: 240px;
      }
      @include breakpoint(xl) {
        width: 300px;
        height: 300px;
      }
    }
    &-astros {
      position: absolute;
      width: 90px;
      height: 90px;
      top: 18px;
      left: 28px;
      @include breakpoint(m) {
        width: 140px;
        height: 140px;
        top: 25px;
        left: 40px;
      }
      @include breakpoint(t) {
        width: 180px;
        height: 180px;
        top: 40px;
        left: 50px;
      }
      @include breakpoint(l) {
        top: 28px;
        left: 40px;
      }
      @include breakpoint(xl) {
        width: 130px;
        height: 130px;
        top: 40px;
        left: 52px;
      }
    }
    .astro-git {
      position: relative;
    }
    .astro-mobile {
      position: absolute;
      width: 105px;
      height: 105px;
      right: 12px;
      top: -70px;
      z-index: 2;
      @include breakpoint(m) {
        width: 130px;
        height: 130px;
        top: -35px;
        right: -80px;
      }
      @include breakpoint(l) {
        top: 60px;
        right: -300px;
      }
      @include breakpoint(xl) {
        right: -270px;
        width: 150px;
        height: 150px;
      }
    }

    .background-icons {
      @include animFade(fadeIn, $anim-timing-600, $anim-ease-utility);
      @include breakpoint(l) {
        position: relative;
        right: 50px;
        top: 320px;
      }
    }

    &-astro {
      @include fadeScaleContent($anim-timing-250);
      margin-top: $spacing-56;
      @include breakpoint(l) {
        margin-top: 0;
      }
    }

    &-lockup {
      @include breakpoint(m) {
        margin-top: 0;
      }
      @include breakpoint(l) {
        margin-top: 0px;
      }
    }

    .cta__astro__container {
      justify-content: center;
      margin: 48px 0px 0px;
      @include breakpoint(l) {
        justify-content: space-between;
      }
    }
  }

  .c360 {
    &__title {
      position: relative;
      height: auto;
      margin: auto;

      .image {
        width: auto;
        height: 26px;

        @include breakpoint(xs) {
          margin: 0 auto;
          height: 23px;
        }

        @include breakpoint(m) {
          margin: 0 auto;
        }

        @include breakpoint(l) {
          margin: 0;
        }
      }

      @include breakpoint(s) {
        padding: $spacing-24 0;
      }
    }
  }

  .hero-container {
    max-width: #{$dw}px;
    @include breakpoint(sm-only) {
      margin-top: $spacing-48;
    }

    @include breakpoint(m) {
      margin-top: $spacing-64;
    }

    @include breakpoint(l) {
      margin-top: $spacing-40;
    }

    @include breakpoint(xl) {
      margin-top: $spacing-80;
    }
  }

  &__hero-content {
    position: relative;
    height: auto;
    padding: 0 0;
    margin: auto;
    display: flex;
    flex-direction: column;

    @include breakpoint(sm-only) {
      padding: 0;
      margin: 0px 24px;
    }
    @include breakpoint(m) {
      padding: 0 0;
    }

    @include breakpoint(l) {
      flex-direction: row;
      padding-top: 20px;
    }

    @include breakpoint(header-l) {
      min-height: 670px;
      // height: calc(80vh - #{$headerDesktopHeight});
      // padding-top: 80px;
    }

    .text {
      @include fadeContent;

      &__eyebrow {
        .image.text__eyebrow-image {
          height: 26px;

          @include breakpoint(m) {
            margin: 0 auto;
          }

          @include breakpoint(l) {
            margin: 0;
          }
        }
      }

      &__cta-container {
        margin-bottom: $spacing-24;
        .text__cta {
          @include breakpoint(sm-only) {
            padding-left: $spacing-8;
            padding-right: $spacing-8;
          }
        }
        .text__cta.text__getintouch {
          @include breakpoint(sm-only) {
            padding-left: $spacing-24;
            padding-right: $spacing-24;
          }
        }
        .text__cta.text__formula {
          font-weight: 800;
        }
      }
      &__cta {
        margin-right: $spacing-8;
        @include breakpoint(m) {
          margin-right: $spacing-16;
        }
      }
    }
  }

  &__intro-container {
    flex: 1;
    text-align: center;
    @include breakpoint(l) {
      text-align: left;
    }
    &.faded-text {
      @include fadeContent;
    }
  }

  &__descri-container {
    // @include breakpoint(l) {
    //   text-align: left;
    // }
    &.faded-text {
      @include fadeContent;
    }
  }

  &__products-container {
    flex: 1;
    @include fadeContent;

    .carousel_intro {
      &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 1s;
        opacity: 1;
        height: 422px;
        @include breakpoint(l) {
          height: 572px;
        }
        &.inactive {
          opacity: 0;
        }
        &.inactive2 {
          height: 0px;
        }
      }
    }
  }

  &__recommended {
    &-header {
      @include fadeScaleContent($anim-timing-600);
      @include type-heading-3;
      color: $brand-dark;
      text-align: center;
      margin-bottom: $spacing-24;
      margin-left: auto;
      margin-right: auto;
    }
    &-lockup {
      @include fadeContent($anim-timing-600);
      margin-top: $spacing-96;
      @include breakpoint(m) {
        margin-top: $spacing-144;
      }
      @include breakpoint(l) {
        margin-top: $spacing-120;
      }
    }
  }
  &__body {
    position: relative;
    &-cta--arrow {
      @include boxShadow;
      z-index: $z-10;
      width: 48px;
      height: 48px;
      bottom: 0;
      transform: translateY(-50%);
      background-color: $white;
      border-radius: 50%;
      margin-bottom: $spacing-64;
      @include breakpoint(m) {
        width: 60px;
        height: 60px;
        margin-bottom: $spacing-80;
      }
      svg {
        stroke: $brand-accessible;
        width: 30px;
        height: 30px;
      }
      &-rot {
        transform: rotate(90deg);
      }
      &-btn {
        @include button-reset;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__contact-section {
    background-image: linear-gradient(to top, #c5e7f8e0, white);
    padding-bottom: $spacing-96;
    padding-top: $spacing-64;
    @include breakpoint(m) {
      background-image: linear-gradient(to top, #c5e7f8e0, #dbf3fde0);
      padding-bottom: $spacing-120;
      padding-top: $spacing-120;
    }
  }
  &__url-input {
    position: absolute;
    overflow: hidden;
    left: -999999px;
  }

  &__copy-success {
    color: $success;
    margin-left: $spacing-8;
    animation: fadeIn $anim-timing-600 $anim-ease-utility;
  }
  &__title {
    @include fadeContent;
    @include type-heading-2;
    color: $brand-dark;
    margin-top: 0;
    margin-bottom: $spacing-24;
    text-align: center;

    @include breakpoint(m) {
      margin-bottom: $spacing-40;
    }
  }
}
