@font-face {
  font-family: "AvantGarde-Demi";
  src: url("./../../assets/fonts/AvantGarde-Demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Regular";
  src: url("./../../assets/fonts/SalesforceSans-Regular.woff2") format("woff2"),
    url("./../../assets/fonts/SalesforceSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Bold";
  src: url("./../../assets/fonts/SalesforceSans-Bold.woff2") format("woff2"),
    url("./../../assets/fonts/SalesforceSans-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SalesforceSans-Thin";
  src: url("./../../assets/fonts/SalesforceSans-Thin.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Salesforce Icons";
  src: url("https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/salesforce-icons.woff2")
    format("woff2");
}
