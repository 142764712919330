.counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-8;
  @include breakpoint(m) {
    margin-top: $spacing-16;
  }
  @include breakpoint(xl) {
    margin-top: $spacing-32;
  }

  &-title {
    @include type-heading-4;
    color: $brand-dark;
  }

  &-progress {
    height: 10px;
    background-color: rgba(3, 45, 96, 0.2);
    border-radius: 5px;
    overflow: hidden;
    width: 50%;
    max-width: 240px;
    &_bar {
      background-color: $brand-dark;
      height: 100%;
      width: 50%;
      transition: width 1s;
    }
  }
}
