.text {
  text-align: center;
  &__extra {
    text-align: left;
  }
  &__eyebrow {
    @include eyebrow;
    display: block;
    margin: 0 auto;
  }

  &__eyebrow-image {
    width: auto;
    margin: 0 auto;
    @include breakpoint(m) {
      margin: 0;
    }
  }

  &__title {
    @include type-heading-2;
    color: $brand-dark;
    margin-top: $spacing-24;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-16;

    &.extra {
      @include type-heading-5;
      @include breakpoint(m) {
        font-size: 32px;
      }
      @include breakpoint(l) {
        font-size: 36px;
      }
      @include breakpoint(sm-only) {
        font-size: 30px;
      }
    }
  }

  &__sub {
    @include type-heading-3;
    color: $brand-dark;
    margin-top: $spacing-24;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-32;
    text-align: center;
  }

  &__subSection {
    @include type-heading-3;
    color: $brand-dark;
    text-align: left;
    margin-top: $spacing-24;
    margin-bottom: 0;
    font-size: 40px;
    @include breakpoint(m) {
      font-size: 40px;
    }
    &.main {
      font-size: 24px;
      @include breakpoint(l) {
        font-size: 36px;
      }
    }
  }

  &__percentageTitle {
    @include type-heading-3;
    color: $white;
    text-align: center;
    // margin-top: $spacing-24;
    // margin-bottom: 0;
    margin: auto;
    font-size: 70px;
    @include breakpoint(m) {
      font-size: 70px;
    }
    &.main {
      font-size: 24px;
    }
  }

  &__plusSymbol {
    color: $white;
    text-align: right;
    font-size: 32px;
    // margin-top: 12px;
    vertical-align: text-top;
    @include breakpoint(sm-only) {
      margin-top: 10px;
    }
  }
  &__percentageSymbol {
    color: $white;
    text-align: left;
    font-size: 32px;
    // margin-top: 12px;
    line-height: 200%;
    vertical-align: text-top;
    @include breakpoint(sm-only) {
      margin-top: 10px;
    }
  }

  &__subtitle {
    @include type-heading-4;
    color: $brand-dark;
    text-align: left;
    // margin-top: 6px;
    // margin-right: auto;
    // font-size: 20px;
    font-size: 16px;
  }

  &__wheel {
    @include type-heading-3;
    color: $brand-dark;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    // @include breakpoint(xl) {
    //   margin-left: $spacing-224;
    // }
  }

  &__body {
    @include type-paragraph-2;
    color: $grey;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-32;

    &.percentage {
      font-size: 12px;
      margin-bottom: 0;
    }

    &.information {
      font-size: 15px;
      margin-bottom: 0;
    }

    &.hero {
      font-size: 15px;
      margin-bottom: 0;
      @include breakpoint(sm-only) {
        font-size: 15px;
      }
      @include breakpoint(l) {
        font-size: 22px;
      }
    }
  }

  &__percentageSection {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    @include fadeContent($anim-timing-250);
    background-color: $brand-primary;
    border-radius: $spacing-16;
    // box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.13);
    opacity: 1 !important;
    // padding: 0 $spacing-12;
    margin: 0 $spacing-40;
  }

  &__list-container {
    margin-top: $spacing-24;
    margin-bottom: $spacing-24;
    @include breakpoint(sm-only) {
      margin-right: $spacing-24;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: row;
  }

  &__list {
    @include type-paragraph-2;
    color: $grey;
    margin-top: 0;
    font-size: 15px;
  }

  &__cta {
    @include button-cta;
    display: inline-block;
    &-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.-reverse {
    .text {
      &__eyebrow,
      &__title,
      &__body {
        color: $white;
      }
      &__cta {
        @include button-cta($type: "reverse");
      }
    }
  }
  &.-left {
    text-align: center;
    &.-center-mobile {
      margin-right: auto;
      margin-left: auto;
      @include breakpoint(l) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    @include breakpoint(l) {
      text-align: left;
    }
  }

  &__customer-lg {
    @include breakpoint(xl) {
      margin-left: 0px;
    }
  }

  &__arrow {
    width: 20px;
    height: 12px;
    margin-right: 12px;
    margin-top: 4px;
    stroke: $brand-primary;
    transition: stroke $anim-timing-250 $anim-ease-utility;
  }
}
