.roles {
  width: 100%;
  height: 100%;

  &__app_container {
    @include questionHeaderAndTitle;
  }

  &__options {
    outline-width: 0;
  }
  &__background-container {
    .background__astro {
      width: px-to-vw(240);
      bottom: 0;
      left: px-to-vw(75);
    }
  }
}
