// Global fade in
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY($anim-translate-y);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Global fade and scale in
@keyframes fadeScaleIn {
  from {
    opacity: 0;
    transform: translateY($anim-translate-y-lg) scale(0.6);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

// Global opacity in
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes buttonAppear {
  0% {
    border-radius: #{$pillBorderRadius * 2};
    transform: scale(0);
  }
  50% {
    border-radius: #{$pillBorderRadius * 1.5};
    transform: scale(1);
  }
  100% {
    border-radius: $pillBorderRadius;
    transform: scale(1);
  }
}

@keyframes buttonAttract {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}

@keyframes inputAppear {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 5000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes nextActiveMobile {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($white, 0.7);
  }
  50% {
    transform: scale(1.15);
    box-shadow: 0 0 0 10px rgba($white, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($white, 0);
  }
}

@keyframes nextActive {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($white, 0.7);
  }
  50% {
    transform: scale(1.25);
    box-shadow: 0 0 0 15px rgba($white, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba($white, 0);
  }
}

@keyframes cloudDrift {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scaleY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
