.background {
  &__container {
    background-image: url("../../../assets/images/backgrounds/SkyGradient.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: $z-neg1;
    pointer-events: none;
    overflow: hidden;
    transform: translateZ(0);
    transform-origin: center;

    &--parallax {
      @include breakpoint(l) {
        transform: scale($parallaxScaleFactor);
        height: calc(100vh - #{$tracker-height});
      }
      img {
        position: absolute;
        height: auto;
        pointer-events: none;
        will-change: transform;
        bottom: 0;
        width: 66%;
      }
    }
    &--static {
      height: calc(100vh - #{$quizFooterMobileHeight});

      &.--full {
        height: 100vh;

        @include breakpoint(touchscreens) {
          position: absolute;
          height: calc(100% - #{$footerMobileHeight});
          @include breakpoint(m) {
            height: calc(100% - #{$footerTabletHeight});
          }
        }
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        object-position: bottom center;
      }
    }
  }

  &__bg {
    z-index: 100;
    width: 100%;
    object-fit: cover;
    transform: translate(-100%, 100%);
  }
  &__clouds-container {
    position: absolute;
    z-index: 200;
    &.-move {
      animation: cloudDrift $anim-timing-100s $anim-ease-utility;
    }
  }
  &__clouds {
    top: 9.375vh;
    position: relative !important;
    width: 6000px;
    height: auto;
    opacity: 0.65;
  }
  &__fg {
    width: 100%;
    z-index: 300;
  }
  &__astro {
    z-index: 400;
    display: none;
    transform: translateY(100%);
    transition: transform $anim-timing-800 $anim-ease-utility;
    &.-move {
      transform: translateY(0);
    }
    @include breakpoint(l) {
      display: block;
    }
  }

  &__bg {
    &.-left,
    &.-left {
      transform: translate(-100%, 100%);
      transition: transform $anim-timing-800 $anim-ease-utility;
      &.-move {
        transform: translate(6%, 0);
        &.-delay {
          transition-delay: $anim-timing-100;
        }
      }
    }
    &.-right,
    &.-right {
      transform: translate(100%, 100%);
      transition: transform $anim-timing-800 $anim-ease-utility;
      &.-move {
        transform: translate(46%, 0);
        &.-delay {
          transition-delay: $anim-timing-100;
        }
      }
    }
  }
  &__fg {
    &.-left,
    &.-left {
      transform: translate(-100%, 100%);
      transition: transform $anim-timing-800 $anim-ease-utility;
      &.-move {
        transform: translate(6%, 0);
        &.-delay {
          transition-delay: $anim-timing-100;
        }
      }
    }
    &.-right,
    &.-right {
      transform: translate(100%, 100%);
      transition: transform $anim-timing-800 $anim-ease-utility;
      &.-move {
        transform: translate(46%, 0);
        &.-delay {
          transition-delay: $anim-timing-100;
        }
      }
    }
  }
}

// Don't animate in if user prefers
@media (prefers-reduced-motion) {
  .background__bg.-left,
  .background__bg.-right,
  .background__fg.-left,
  .background__fg.-right,
  .background__astro {
    transition: none;
  }
}
