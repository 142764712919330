.app {
  &__container {
    width: 100%;
    position: relative;
    z-index: $z-1;

    main {
      min-height: calc(100% - #{$headerMobileHeight} - #{$footerMobileHeight});

      @include breakpoint(m) {
        min-height: calc(
          100% - #{$headerMobileHeight} - #{$footerTabletHeight}
        );
      }

      @include breakpoint(l) {
        min-height: calc(
          100% - #{$headerMobileHeight} - #{$footerDesktopHeight}
        );
      }

      @include breakpoint(gn) {
        min-height: calc(
          100% - #{$headerDesktopHeight} - #{$footerDesktopHeight}
        );
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
#skip-links {
  z-index: 1000;
  background-color: $white;
}

.keeptogether {
  white-space: nowrap;
}

.breakline {
  display: block;
}

.superset {
  vertical-align: super;
  font-size: 0.6em;
  line-height: 0.6em;
  font-weight: 400;
}
