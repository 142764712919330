.notFound {
  text-align: center;
  .background__container--static {
    height: 100%;
    @include breakpoint(m) {
      height: 100vh;
    }
  }
  &__wrap {
    margin-top: $spacing-120;
    @include breakpoint(m) {
      margin-top: $spacing-96;
    }
  }
  &__title {
    @include type-heading-1;
    color: $brand-dark;
    padding: 0 $spacing-48;
    text-align: center;
    margin-bottom: 0;
  }

  &__cta {
    @include button-reset;
    @include button-cta;
    display: inline-block;
    margin-top: $spacing-32;
  }
}
